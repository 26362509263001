import React, {useState, useEffect} from 'react'
import User from '../../api/services/User';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const BuddyCard = ({ user, to = '' }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Link className='w-full' to={ "/buddymaker/" + user.id }>
                <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
                    <div className="relative w-16 shrink-0">
                        <div className='relative'>
                            {
                                loading ?
                                <div className="bg-black h-16 w-16 object-cover flex items-center justify-center"><FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' /></div>
                                :
                                <img src={user.image_url} alt="" className="h-16 w-16  rounded-full object-cover object-center" />
                            }
                            <div className='absolute -right-1 -top-1'>
                                <CheckBadgeIcon className='size-6 text-primary' />
                            </div>
                        </div>
                    </div>
                  <div className='flex flex-col justify-center grow pl-5 text-start'>
                    <div style={{ fontSize: '17px',fontWeight: '500' }} className="text-white">{ user.name }</div>
                    <div style={{ fontSize: '15px',fontWeight: '400' }} className='ml-1 text-buddy-text-3'>Recetas subidas: <span style={{ fontWeight: '500' }} className="text-primary">{ user.recipes_count }</span></div>
                  </div>
                  <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0 mt-auto mb-auto' />
                </div>
            </Link>
        </>
    )
}

export default BuddyCard 