import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const LoginInput = ({ children, ...props }) => {
    return (
        <>
            <div className='flex flex-col gap-2 items-start'>
                <label className="font-semibold text-md text-buddy-text-1">{props.label}</label>
                <div className="w-full h-14 rounded-lg bg-transparent relative ring-login-text-color ring-1 ring-inset">
                    <div className='flex items-center justify-center absolute w-16 h-full'>
                        {children}
                    </div>
                    <input className="w-full h-full !bg-transparent text-lg text-buddy-text-1 placeholder:text-buddy-text-2 rounded-lg focus:ring-1 focus:ring-inset hover:ring-0 ps-16"
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        name={props.name}
                        autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff"
                    />
                </div>
            </div>
        </>
    )
}


export default LoginInput 