import { createContext, useState } from "react";

export const ModalContext = createContext();

export function ModalProvider ({children}) {
    
    const [show, setShow] = useState(false);

    return (
        <ModalContext.Provider value = {{ setShow }}>
            {
                show ? 
                <div className="absolute flex z-50 bg-black w-full h-screen bg-opacity-40 p-8">
                    <div className="relative w-full m-auto p-4 bg-white rounded-lg">
                        
                    </div>
                </div>
                : ''
            }
            {children}
        </ModalContext.Provider>
    )
}