import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import FoodCategory from "../api/services/FoodCategory.js";
import Recipe from "../api/services/Recipe.js";
import Toolbar from "./ui/Toolbar.js";
import Input from "./forms/Input.js";
import Textarea from "./forms/Textarea.js";
import Measure from "../api/services/Measure.js";
import Ingredient from "../api/services/Ingredient.js";
import SelectRounded from "./forms/SelectRounded.js";
import SmallToggle from "./forms/SmallToggle.js";
import FooterToolbar from "./ui/FooterToolbar.js";
import PrimaryButton from "./ui/PrimaryButton.js";
import SecondaryButton from "./ui/SecondaryButton.js";
import SecondaryOutLineButton from "./ui/SecondaryOutlineButton.js";
import { CameraIcon, CheckIcon, PlusIcon, PencilIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";

const RecipeCreate = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [foodCategoryOptions, setFoodCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [status, setStatus] = useState(0);
  const [description, setDescription] = useState('');

  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(null);

  const [allIngredients, setAllIngredients] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [ingredientSearchEnabled, setIngredientSearchEnabled] = useState(true);
  const [ingredient, setIngredient] = useState(null);
  const [ingredientHeaders, setIngredientHeaders] = useState([]);

  const [recipeSteps, setRecipeSteps] = useState([]);
  const [stepHeaders, setStepHeaders] = useState([]);

  const [recipeStep, setRecipeStep] = useState('');
  const [showEditStepForm, setShowEditStepForm] = useState(false);
  const [editStepIndex, setEditStepIndex] = useState(null);

  const [measureId, setMeasureId] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);

  const [name, setName] = useState('');
  const [rations, setRations] = useState('');
  const [cookTime, setCookTime] = useState('');
  const [preparationTime, setPreparationTime] = useState('');

  const [editIngredientDetails, setEditIngredientDetails] = useState(null);
  const [editStepDetails, setEditStepDetails] = useState(null);

  const [newIngredientHeader, setNewIngredientHeader] = useState('');
  const [newStepHeader, setNewStepHeader] = useState('');

  const [showIngredientHeaderInput, setShowIngredientHeaderInput] = useState(false);
  const [showStepHeaderInput, setShowStepHeaderInput] = useState(false);

  const [editIngredientHeaderIndex, setEditIngredientHeaderIndex] = useState(null);
  const [editStepHeaderIndex, setEditStepHeaderIndex] = useState(null);

  const statusOptions = [
    {
      value: 1,
      label: "Publicada"
    },
    {
      value: 0,
      label: "Borrador"
    }
  ];

  useEffect(() => {
    getFoodCategories();
    getMeasures();
    getAllIngredients();
  }, []);

  const getAllIngredients = async () => {
    try {
      const response = await Ingredient.list();
      setAllIngredients(response.data);
    } catch (error) {
      console.error('Error fetching Ingredients:', error);
    }
  };

  const getIngredients = (search) => {
    if (!search || search.length < 3) {
      setIngredients([]);
      return;
    }

    const filteredIngredients = allIngredients
      .filter(ingredient => ingredient.name.toLowerCase().includes(search.toLowerCase()))
      .slice(0, 10);
    setIngredients(filteredIngredients);
  };

  const handleStore = async () => {
    try {
      const body = new FormData();
      body.append('name', name);
      body.append('image_file', fileInputRef.current.files[0]);
      body.append('description', description);
      body.append('preparation_time', parseInt(preparationTime));
      body.append('cook_time', parseInt(cookTime));
      body.append('rations', parseInt(rations));
      body.append('food_category_ids', JSON.stringify(selectedCategories.map(c => ({ id: c.id }))));
      body.append('ingredients', JSON.stringify(ingredientHeaders));
      body.append('steps', JSON.stringify(stepHeaders));
      body.append('active', status);

      const response = await Recipe.create(body);
      navigate('/', { replace: true });
    } catch (error) {
      alert(error.response?.payload?.message ?? 'Error inesperado. Inténtalo de nuevo.');
      console.error('Error creating recipe:', error);
    }
  };

  const getFoodCategories = async () => {
    try {
      const response = await FoodCategory.list();
      setFoodCategoryOptions(response.data);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      var options = [];
      response.data.forEach(type => {
        options.push({
          value: type.id,
          label: type.name
        });
      });
      setMeasureOptions(options);
      setMeasureId(options[0].value);
    } catch (error) {
      console.error('Error fetching Measure:', error);
    }
  };

  const addIngredient = (headerIndex) => {
    if (ingredientHeaders.length === 0) {
      alert("Primero debes crear un encabezado para los ingredientes.");
      return;
    }

    if (document.querySelector('[name="quantity"]').value === '') {
      alert("Es necesario indicar la cantidad");
    } else {
      const measure = measureOptions.find(option => option.value === measureId);
      const updatedHeaders = ingredientHeaders.map((item, index) => {
        if (index === headerIndex) {
          return {
            ...item,
            ingredients: [
              ...item.ingredients,
              {
                ingredient_id: ingredient.id,
                quantity: document.querySelector('[name="quantity"]').value,
                measure_id: measureId,
                label: ingredient.name,
                image_url: ingredient?.image_url,
                measure_label: measure.label,
              }
            ]
          };
        }
        return item;
      });
      setIngredientHeaders(updatedHeaders);

      handleResetIngredient();
      document.querySelector('[name="quantity"]').value = '';
    }
  };

  const addStep = (headerIndex) => {
    if (stepHeaders.length === 0) {
      alert("Primero debes crear un encabezado para los pasos.");
      return;
    }

    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        return {
          ...item,
          steps: [
            ...item.steps,
            { description: document.querySelector('[name="recipe_step"]').value }
          ]
        };
      }
      return item;
    });
    setStepHeaders(updatedHeaders);
    setRecipeStep('');
    document.querySelector('[name="recipe_step"]').value = '';
  };

  const handleDeleteIngredient = (headerIndex, ingredientIndex) => {
    const updatedHeaders = ingredientHeaders.map((item, index) => {
      if (index === headerIndex) {
        return {
          ...item,
          ingredients: item.ingredients.filter((_, i) => i !== ingredientIndex)
        };
      }
      return item;
    });
    setIngredientHeaders(updatedHeaders);
  };

  const handleDeleteStep = (headerIndex, stepIndex) => {
    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        return {
          ...item,
          steps: item.steps.filter((_, i) => i !== stepIndex)
        };
      }
      return item;
    });
    setStepHeaders(updatedHeaders);
  };

  const handleEditIngredient = (headerIndex, ingredientIndex) => {
    const ingredientToEdit = ingredientHeaders[headerIndex].ingredients[ingredientIndex];
    setEditIngredientDetails({ headerIndex, ingredientIndex, ...ingredientToEdit });

    setIngredientSearch(ingredientToEdit.label);
    setIngredientSearchEnabled(false);
    setMeasureId(ingredientToEdit.measure_id);
    document.querySelector('[name="quantity"]').value = ingredientToEdit.quantity;
  };

  const saveEditedIngredient = () => {
    if (!editIngredientDetails) return;
    const { headerIndex, ingredientIndex } = editIngredientDetails;
    const updatedHeaders = ingredientHeaders.map((item, index) => {
      if (index === headerIndex) {
        const updatedIngredients = item.ingredients.map((ing, i) => {
          if (i === ingredientIndex) {
            return {
              ...ing,
              quantity: document.querySelector('[name="quantity"]').value,
              measure_id: measureId,
            };
          }
          return ing;
        });
        return { ...item, ingredients: updatedIngredients };
      }
      return item;
    });

    setIngredientHeaders(updatedHeaders);
    setEditIngredientDetails(null);
    handleResetIngredient();
  };

  const handleEditStep = (headerIndex, stepIndex) => {
    const stepToEdit = stepHeaders[headerIndex].steps[stepIndex];
    setEditStepDetails({ headerIndex, stepIndex, description: stepToEdit.description });
    setRecipeStep(stepToEdit.description);
  };

  const saveEditedStep = () => {
    if (!editStepDetails) return;

    const { headerIndex, stepIndex } = editStepDetails;
    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        const updatedSteps = item.steps.map((step, i) => {
          if (i === stepIndex) {
            return { ...step, description: recipeStep };
          }
          return step;
        });
        return { ...item, steps: updatedSteps };
      }
      return item;
    });

    setStepHeaders(updatedHeaders);
    setEditStepDetails(null);
    setRecipeStep('');
  };

  const handleOnChangeIngredientSearch = (e) => {
    const search = e.target.value;
    setIngredientSearch(search);
    getIngredients(search);
  };

  const handleOnFocusIngredientSearch = (e) => {
    if (ingredientSearchEnabled) {
      const ingredientSearch = e.target.value;
      if (ingredientSearch.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(e.target.value);
      }
    }
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  const handleResetIngredient = () => {
    setIngredientSearch('');
    document.querySelector('[name="ingredient_search"]').value = '';
    setIngredients([]);
    setIngredient(null);
    setIngredientSearchEnabled(true);
  };

  const handleOnIngredientSelected = (selectedIngredient) => {
    document.querySelector('[name="ingredient_search"]').value = selectedIngredient.name;
    setIngredientSearch(selectedIngredient.name);
    setIngredients([]);
    setIngredientSearchEnabled(false);
    setIngredient(selectedIngredient);
  };

  const handleOnChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleOnChangeStatus = (value) => {
    setStatus(value);
  };

  const onChangeImageFile = e => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onResetImageFile = e => {
    setImageFile(null);
    setImageData(null);
  };

  const handleOnStepChange = e => {
    setRecipeStep(e.target.value);
  };

  const handleCategorySelect = (category) => {
    if (!selectedCategories.some(selected => selected.id === category.id)) {
      setSelectedCategories([...selectedCategories, category]);
      setCategorySearch('');
    }
  };

  const handleRemoveCategory = (category) => {
    setSelectedCategories(selectedCategories.filter(selected => selected.id !== category.id));
  };

  const saveEditedIngredientHeader = (headerIndex) => {
    if (!newIngredientHeader.trim()) {
      alert("El encabezado no puede estar vacío.");
      return;
    }
    const updatedHeaders = ingredientHeaders.map((item, index) => {
      if (index === headerIndex) {
        return { ...item, header: newIngredientHeader };
      }
      return item;
    });

    setIngredientHeaders(updatedHeaders);
    setEditIngredientHeaderIndex(null);
    setNewIngredientHeader('');
  };

  const saveEditedStepHeader = (headerIndex) => {
    if (!newStepHeader.trim()) {
      alert("El encabezado no puede estar vacío.");
      return;
    }
    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        return { ...item, header: newStepHeader };
      }
      return item;
    });

    setStepHeaders(updatedHeaders);
    setEditStepHeaderIndex(null);
    setNewStepHeader('');
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={"/"} replace={true}>
          <div className='text-md'>
            <span>Crear Receta</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-4 space-y-3">

          <Input 
            name="name" 
            label={'Nombre de la receta'} 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />

          <input
            ref={fileInputRef}
            type="file"
            name="image_file"
            onChange={onChangeImageFile}
            className="hidden"
          />

          { 
            imageFile ? 

            <div className="flex justify-center py-4">
              <div className="relative flex justify-center items-center w-52 h-52">
                <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4 overflow-hidden">
                  <img onClick={() => fileInputRef.current.click()} className="h-full w-full object-cover object-center" src={imageData} />
                </div>
                <button type='button' onClick={onResetImageFile} className='absolute -right-2 -top-2 flex items-center justify-center w-8'>
                  <div className='w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center'>
                      <FontAwesomeIcon className='text-white' icon={faTimes} />
                  </div>
                </button>
              </div>
            </div>
            :
            <div className="flex justify-center py-4">
              <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4">
                <button onClick={() => fileInputRef.current.click()} className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2">
                  <CameraIcon className="size-6" />
                  <span className="text-sm">Añadir foto de portada</span>
                </button>
              </div>
            </div>
          }

          <Textarea onChange={handleOnChangeDescription} name="description" label="Descripción del plato" />

          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Ingredientes</p> 

            <button
              onClick={() => setShowIngredientHeaderInput(true)}
              style={{ backgroundColor: "#394a53" }}
              className="text-primary rounded-lg py-1 px-3 mb-3 inline-flex items-center space-x-2"
            >
              <PlusIcon className="size-4" /> <span>Añadir encabezado</span>
            </button>

            {showIngredientHeaderInput && (
              <div className="flex flex-col space-x-4 mb-3">
                <div className="w-full shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                  <input
                    type="text"
                    value={newIngredientHeader}
                    onChange={(e) => setNewIngredientHeader(e.target.value)}
                    className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                    placeholder="Introduce un encabezado para los ingredientes"
                  />
                </div>
                <div className="flex justify-end mt-2">
                  <SecondaryButton
                    onClick={() => {
                      if (newIngredientHeader.trim()) {
                        setIngredientHeaders([...ingredientHeaders, { header: newIngredientHeader, ingredients: [] }]);
                        setNewIngredientHeader('');
                      }
                      setShowIngredientHeaderInput(false);
                    }}
                  >
                    <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary">Guardar</span>
                  </SecondaryButton>
                </div>
              </div>
            )}

            {ingredientHeaders.map((group, headerIndex) => (
              <div key={headerIndex}>
                {editIngredientHeaderIndex === headerIndex ? (
                  <input
                    type="text"
                    value={newIngredientHeader}
                    onChange={(e) => setNewIngredientHeader(e.target.value)}
                    onBlur={() => saveEditedIngredientHeader(headerIndex)}
                    className="bg-transparent text-white mt-2 mb-1"
                    autoFocus
                  />
                ) : (
                  <h3
                    className="text-orange-500 font-semibold my-2 cursor-pointer"
                    onClick={() => {
                      setEditIngredientHeaderIndex(headerIndex);
                      setNewIngredientHeader(group.header);
                    }}
                  >
                    {group.header}
                  </h3>
                )}
                {group.ingredients.map((ingredient, i) => (
                  <div key={i} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                    <img className="w-5 h-5 rounded-full" src={ingredient.image_url} alt={ingredient.label}></img>
                    <div className="grow">{ingredient.label} {ingredient.quantity} {ingredient.measure_label}</div>
                    <button onClick={() => handleEditIngredient(headerIndex, i)}>
                      <PencilIcon className="size-5 text-buddy-text-primary" />
                    </button>
                    <button onClick={() => handleDeleteIngredient(headerIndex, i)}>
                      <TrashIcon className="size-5 text-buddy-text-danger" />
                    </button>
                  </div>
                ))}
              </div>
            ))}

            <div className={'flex justify-content-center space-x-4 mt-14'}>
              <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                {ingredientSearch.length > 0 && (
                  <div className="absolute right-0 bottom-0 top-0">
                    <button type='button' onClick={handleResetIngredient} className='flex items-center justify-center w-8 me-2 h-full'>
                      <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                        <FontAwesomeIcon className='text-white' icon={faTimes} />
                      </div>
                    </button>
                  </div>
                )}
                <input readOnly={!ingredientSearchEnabled} onFocus={handleOnFocusIngredientSearch} onChange={handleOnChangeIngredientSearch} autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                    name="ingredient_search"
                    type="text"
                    placeholder="Buscar ingredientes"
                />
                <div className="absolute left-2 top-4">
                  <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                </div>
              </div>
              <div className="flex flex-col w-1/4 text-buddy-text-1 -mt-6">
                <span className="text-xs text-center">Unidad</span>
                <SelectRounded name="measure_id" onChange={handleOnChangeMeasureId} options={measureOptions} />
              </div>
              <div className="flex flex-col text-buddy-text-1 w-1/4 -mt-6">
                <span className="text-xs mb-2 text-center">Cantidad</span>
                <div className='w-full'>
                  <div className="w-full h-11 bg-secondary-bg rounded-lg relative shadow-inner ring-white ring-2">
                    <input aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-center text-sm text-white placeholder:font-light rounded-lg"
                      name="quantity"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="ingredientsList" className="w-full z-10 absolute flex flex-col bg-opacity-90 bg-buddy-bg-5 rounded-lg overflow-hidden">
              {
                ingredients.map((ingredient, i) => (
                  <button key={i} onClick={() => handleOnIngredientSelected(ingredient)} className="w-full text-start text-buddy-text-2 py-2 px-3">{ingredient.name}</button>
                ))
              }
            </div> 
            {
              !ingredientSearchEnabled &&
              <div className="flex justify-end space-x-2 mt-3">
                <SecondaryOutLineButton onClick={handleResetIngredient}>
                  <XMarkIcon className="size-4 me-1" /> <span> Cancelar</span>
                </SecondaryOutLineButton>
                <SecondaryButton onClick={() => editIngredientDetails ? saveEditedIngredient() : addIngredient(ingredientHeaders.length - 1)}>
                  <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary"> Guardar</span>
                </SecondaryButton>
              </div>
            }
          </div>

          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Instrucciones</p> 

            <button
              onClick={() => setShowStepHeaderInput(true)}
              style={{ backgroundColor: "#394a53" }}
              className="text-primary rounded-lg py-1 px-3 mb-3 inline-flex items-center space-x-2"
            >
              <PlusIcon className="size-4" /> <span>Añadir encabezado</span>
            </button>

            {showStepHeaderInput && (
              <div className='flex flex-col space-x-4 mb-3'>
                <div className="w-full shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                  <input
                    type="text"
                    value={newStepHeader}
                    onChange={(e) => setNewStepHeader(e.target.value)}
                    className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                    placeholder="Introduce un encabezado para los pasos"
                  />
                </div>
                <div className="flex justify-end mt-2">
                  <SecondaryButton
                    onClick={() => {
                      if (newStepHeader.trim()) {
                        setStepHeaders([...stepHeaders, { header: newStepHeader, steps: [] }]);
                        setNewStepHeader('');
                      }
                      setShowStepHeaderInput(false);
                    }}
                  >
                    <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary">Guardar</span>
                  </SecondaryButton>
                </div>
              </div>
            )}

            {stepHeaders.map((group, headerIndex) => (
              <div key={headerIndex}>
                {editStepHeaderIndex === headerIndex ? (
                  <input
                    type="text"
                    value={newStepHeader}
                    onChange={(e) => setNewStepHeader(e.target.value)}
                    onBlur={() => saveEditedStepHeader(headerIndex)}
                    className="bg-transparent text-white mt-2 mb-1"
                    autoFocus
                  />
                ) : (
                  <h3
                    className="text-orange-500 font-semibold my-2 cursor-pointer"
                    onClick={() => {
                      setEditStepHeaderIndex(headerIndex);
                      setNewStepHeader(group.header);
                    }}
                  >
                    {group.header}
                  </h3>
                )}
                {group.steps.map((step, i) => (
                  <div key={i} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                    <div className="grow">{step.description}</div>
                    <button onClick={() => handleEditStep(headerIndex, i)}>
                      <PencilIcon className="size-5 text-buddy-text-primary" />
                    </button>
                    <button onClick={() => handleDeleteStep(headerIndex, i)}>
                      <TrashIcon className="size-5 text-buddy-text-danger" />
                    </button>
                  </div>
                ))}
              </div>
            ))}

            <div className='flex space-x-4 mt-10'>
              <div className="w-full shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                <input onChange={handleOnStepChange} value={recipeStep} aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                      name="recipe_step"
                      type="text"
                      placeholder="Añadir paso"
                />
              </div>
            </div>

            { recipeStep !== '' &&
              <div className="flex justify-end mt-2">
                <SecondaryButton onClick={() => editStepDetails ? saveEditedStep() : addStep(stepHeaders.length - 1)}>
                  <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary">Guardar</span>
                </SecondaryButton>
              </div>
            }
          </div>

          <Input 
            name="rations" 
            label={"Establecer raciones"} 
            type="number" 
            value={rations}
            onChange={(e) => setRations(e.target.value)} 
          />
          <div className="text-xs text-primary mt-1 mb-2">Esto se utiliza para calcular las raciones en base a las cantidades y calcular el valor nutricional de la receta.</div>

          <Input 
            name="cook_time" 
            label={"Tiempo de cocción (minutos)"} 
            type="number" 
            value={cookTime}
            onChange={(e) => setCookTime(e.target.value)} 
          />

          <Input 
            name="preparation_time" 
            label={"Tiempo de preparación (minutos)"} 
            type="number" 
            value={preparationTime}
            onChange={(e) => setPreparationTime(e.target.value)} 
          />

<div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Categorías</p> 

            <input 
              type="text" 
              value={categorySearch} 
              onChange={(e) => setCategorySearch(e.target.value)} 
              className="w-full p-2 bg-white rounded mb-2" 
              placeholder="Buscar categoría"
            />
            
            <div className="flex flex-wrap gap-2">
              {selectedCategories.map(category => (
                <div key={category.id} className="flex items-center px-3 py-1 bg-white rounded cursor-pointer">
                  {category.name}
                  <button onClick={() => handleRemoveCategory(category)} style={{ backgroundColor: "rgb(255, 140, 0)" }} className="ml-2 text-white px-1 rounded">×</button>
                </div>
              ))}
              {foodCategoryOptions
                .filter(c => c.name.toLowerCase().includes(categorySearch.toLowerCase()))
                .filter(c => !selectedCategories.some(selected => selected.id === c.id))
                .map(category => (
                  <div 
                    key={category.id}
                    onClick={() => handleCategorySelect(category)}
                    style={{ backgroundColor: "#424E55", color: "white" }}
                    className="flex items-center px-3 py-1 rounded cursor-pointer"
                  >
                    {category.name}
                  </div>
              ))}
            </div>
          </div>

          <div className="">
            <SmallToggle selected={status} onChange={handleOnChangeStatus} options={statusOptions} label="Estado de la Receta" />
          </div>

          <div className="my-4"></div>

          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleStore}>Guardar</PrimaryButton>
          </FooterToolbar>

        </div>
      </div>
    </div>
  );
};

export default RecipeCreate;