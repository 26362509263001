import React, { useState, useEffect } from 'react';
import logobuddy from '../img/b-de-buddy.png';
import bgLogin from '../img/bg-login-2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom"; 
import PrimaryTextLink from './ui/PrimaryTextLink';
import PrimaryButton from './ui/PrimaryButton';
import Card from './ui/Card';
import Auth from '../api/services/Auth';
import LoginInput from './forms/LoginInput';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth'; // Ensure correct import

const CLIENT_ID = '909562975875-dn7h3ihlupjdgb6hnj56illp2dc6jvek.apps.googleusercontent.com';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Auth.login({ email, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      await getProfile();
      localStorage.setItem('register_data', null);
    } catch (error) {
      console.error(error);
      if (error.code === 403) {
        alert('Usuario o contraseña incorrecto');
      }
    }
  };

  const getProfile = async () => {
    try {
      const response = await Auth.profile();
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/');
    } catch (error) {
      if (error.code === 401) {
        navigate('login', { replace: true });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/', { replace: true });
    }
  }, []);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;

      const response = await Auth.loginGoogle({ token: credential });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      navigate('/');
    } catch (error) {
      alert('Debes registrarte primero');
      console.error('Error logging in with Google:', error);
    }
  };

  const handleGoogleLoginFailure = () => {
    alert('Login failed, please try again.');
  };

  const handleAppleLoginSuccess = async (response) => {
    try {
      const { authorization } = response;
      const identityToken = authorization.id_token;
      const apiResponse = await Auth.loginApple({ token: identityToken });
      localStorage.setItem('token', apiResponse.data.token);
      localStorage.setItem('user', JSON.stringify(apiResponse.data.user));
      navigate('/');
    } catch (error) {
      alert('Debes registrarte primero');
      console.error('Error logging in with Apple:', error);
    }
  };

  const handleAppleLoginFailure = (error) => {
    console.error('Error with Apple Sign-In:', error);
  };

  const bgImageStyle = {
    backgroundImage: `url(${bgLogin})`,
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <div className='h-screen bg-no-repeat bg-cover bg-center' style={bgImageStyle}>
        <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col bg-red items-center gap-6">
              <img className="w-2/5 z-10" src={logobuddy} alt="logo BuddyMarket" />
              <Card className="pt-14 -mt-16 bg-primary-bg">
                <h1 className='font-title text-2xl text-white font-bold mb-4 text-center'>Bienvenid@ a BuddyMarket</h1>
                <p className='text-md px-8 text-center text-body font-light'>
                  Donde tus gustos y nuestra inteligencia artificial cocinan juntos la lista de compras perfecta.
                </p>
              </Card>

              <div className='w-full flex flex-col gap-4'>
                <LoginInput label="Usuario" type="text" name="email" placeholder="Correo electrónico" onChange={e => setEmail(e.target.value)}>
                  <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5 text-login-text-color" />
                </LoginInput>

                <LoginInput label="Contraseña" type="password" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)}>
                  <FontAwesomeIcon icon={faLock} className="w-5 h-5 text-login-text-color" />
                </LoginInput>

                <div className="text-right">
                  <Link className='text-primary' to="/resetpassword">¿Has olvidado tu contraseña?</Link>
                </div>
              </div>

              <div className='flex flex-col items-center w-full space-y-4'>
                <AppleSignin
                  authOptions={{
                    clientId: 'io.dummy.buddymarket',
                    scope: 'email',
                    redirectURI: 'https://dummy.buddymarket.io/login',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true
                  }}
                  onSuccess={handleAppleLoginSuccess}
                  onError={handleAppleLoginFailure}
                  render={(props) => <button {...props} className='flex justify-center items-center bg-black text-white w-full px-4 py-4 rounded-xl'>Continuar con Apple</button>}
                />

                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginFailure}
                />
              </div>

              <div className="w-full mt-10">
                <PrimaryButton type="submit">Iniciar sesión</PrimaryButton>
                <div className='my-4'></div>
                <PrimaryTextLink to="/SignUp">Registrarse</PrimaryTextLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;