import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { faChevronLeft, faL } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserMenuOrganizer from "../api/services/UserMenuOrganizer.js";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import image from '../img/organizador_bg.png';
import InputState from "./forms/InputState.js";
import PrimaryButton from "./ui/PrimaryButton.js";


const MenuOrganizerShow = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [menuOrganizer, setMenuOrganizer] = useState();
  const [loading, setLoading] = useState(false);
  const [weekIndex, setWeekIndex] = useState(null);
  const [week, setWeek] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

 
  const id = searchParams.get('id') ?? '';
  const user = JSON.parse(localStorage.getItem('user'));
 
  useEffect(() => {    
    getMenuOrganizer();
  }, []);

  const getMenuOrganizer = async () => {
    setLoading(true);
    try {
      const response = await UserMenuOrganizer.get(user.id, id);
      setMenuOrganizer(response.data);
      setWeekIndex(searchParams.get("week"));
      setWeek(searchParams.get("week") !== null ? response.data.weeks[searchParams.get("week")] : null);
    } catch (error) {
      console.error('Error fetching organizer:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setWeekIndex(searchParams.get("week"));
  }, [searchParams]);

  useEffect(() => {
    if (menuOrganizer) {
      setWeek(weekIndex !== null ? menuOrganizer.weeks[weekIndex] : null);
    }
  }, [weekIndex]);

  const handleOnBack = () => {
    if (showMenu) {
      setShowMenu(false);
    } else if (week != null) {
      setWeek(null);
      setSearchParams({ id: id });
    } else {
      navigate('/menuOrganizerList', { replace: true});
    }
  }

  const handleOnDelete = async () => {
    if (window.confirm("Eliminar Organizador de menú") == true) {
      try {
        const response = await UserMenuOrganizer.delete(user.id, id);
        navigate('/menuOrganizerList');
      } catch (error) {
        console.error('Error deleting organizer:', error);
      }
    }
  }

  const handleOnUpdate = async () => {
    try {
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      const response = await UserMenuOrganizer.update(user.id, id, body);
      setShowEditForm(false);
      getMenuOrganizer();
    } catch (error) {
      console.error('Error updating organizer:', error);
    }
  }

  const handleOnSelectWeek = (index) => {
    setSearchParams({ id: id, week: index });
    setWeekIndex(index);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
      {
          showMenu &&
          <div onClick={ (e) => { setShowMenu(false) } }  className="absolute top-20 left-0 w-full h-screen text-white bg-black bg-opacity-60">
              <div className="mx-0 border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
                <button onClick={ handleOnDelete } className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3">
                  <div className="pointer-events-none">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_545_3355)">
                    <path d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z" fill="#FF8C00"/>
                    <g clipPath="url(#clip1_545_3355)">
                    <path d="M11.6337 2.56292L9.43708 0.365833C9.20083 0.13 8.88667 0 8.55333 0H5.44667C5.11292 0 4.79917 0.13 4.56292 0.36625L2.36625 2.56292C2.13 2.79917 2 3.11292 2 3.44667V6.55333C2 6.88708 2.13 7.20083 2.36625 7.43708L4.56292 9.63375C4.79917 9.87 5.11292 10 5.44667 10H8.55333C8.88667 10 9.20083 9.87 9.4375 9.63375L11.6337 7.43708C11.8696 7.20083 12 6.88708 12 6.55333V3.44667C12 3.11292 11.8696 2.79917 11.6337 2.56292ZM11.1667 6.55333C11.1667 6.66292 11.1225 6.77042 11.0446 6.84792L8.84833 9.04417C8.76917 9.12292 8.66417 9.16667 8.55292 9.16667H5.44625C5.33667 9.16667 5.22917 9.12208 5.15167 9.04458L2.955 6.84792C2.8775 6.77 2.83292 6.66292 2.83292 6.55333V3.44667C2.83292 3.33708 2.8775 3.22958 2.955 3.15208L5.15208 0.955417C5.23 0.877917 5.33708 0.833333 5.44667 0.833333H8.55333C8.66458 0.833333 8.76958 0.876667 8.84833 0.955417L11.0446 3.15208C11.1225 3.23 11.1667 3.33708 11.1667 3.44667V6.55333ZM8.84958 3.73958L7.58167 5.0075L8.84958 6.27542L8.26042 6.86458L6.9925 5.59667L5.72458 6.86458L5.13542 6.27542L6.40333 5.0075L5.13542 3.73958L5.72458 3.15042L6.9925 4.41833L8.26042 3.15042L8.84958 3.73958Z" fill="#FF8C00"/>
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_545_3355">
                    <rect width="28" height="28" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_545_3355">
                    <rect width="10" height="10" fill="white" transform="translate(2)"/>
                    </clipPath>
                    </defs>
                    </svg>

                  </div>
                  <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">Eliminar Organizador de menús</span>
                  <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
                </button>
                <button onClick={ (e) => { setShowMenu(false); setShowEditForm(true); } } className="w-full bg-buddy-bg-5 flex justify-between items-center px-3 py-2 rounded-lg space-x-3">
                  <div className="pointer-events-none">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_545_3381)">
                    <path d="M22.1667 15.1667H10.5001V12.8333H22.1667V15.1667ZM22.1667 8.16667H14.0001V10.5H22.1667V8.16667ZM10.5001 19.8333H15.1667V17.5H10.5001V19.8333ZM28.0001 3.5V19.1497L19.1497 28H4.66675V12.8333H7.00008V25.6667H17.5001V17.5H25.6667V3.5C25.6667 2.856 25.1441 2.33333 24.5001 2.33333H16.3334V0H24.5001C26.4332 0 28.0001 1.56683 28.0001 3.5ZM19.8334 24.017L24.0171 19.8333H19.8334V24.017Z" fill="#FF8C00"/>
                    <g clipPath="url(#clip1_545_3381)">
                    <path d="M11.8387 4.16667C11.9675 4.16667 12.0892 4.10708 12.1679 4.00542C12.2467 3.90375 12.2742 3.77125 12.2425 3.64667C12.1129 3.13958 11.8487 2.67625 11.4787 2.30625L10.0267 0.854167C9.47583 0.303333 8.74333 0 7.96417 0H6.08292C4.93458 0 4 0.934583 4 2.08333V7.91667C4 9.06542 4.93458 10 6.08333 10H7.33333C7.56333 10 7.75 9.81333 7.75 9.58333C7.75 9.35333 7.56333 9.16667 7.33333 9.16667H6.08333C5.39417 9.16667 4.83333 8.60583 4.83333 7.91667V2.08333C4.83333 1.39417 5.39417 0.833333 6.08333 0.833333H7.96458C8.0325 0.833333 8.1 0.836667 8.16667 0.842917V2.91667C8.16667 3.60583 8.7275 4.16667 9.41667 4.16667H11.8387ZM9 2.91667V1.10792C9.15792 1.19875 9.305 1.31125 9.4375 1.44375L10.8896 2.89583C11.0204 3.02667 11.1325 3.17417 11.2242 3.33333H9.41667C9.18708 3.33333 9 3.14625 9 2.91667ZM13.6342 4.94958C13.1617 4.47708 12.3383 4.47708 11.8662 4.94958L9.07167 7.74417C8.75708 8.05875 8.58333 8.4775 8.58333 8.92292V9.58375C8.58333 9.81375 8.77 10.0004 9 10.0004H9.66083C10.1062 10.0004 10.5246 9.82667 10.8392 9.51208L13.6337 6.7175C13.87 6.48125 14 6.1675 14 5.83333C14 5.49917 13.87 5.18542 13.6342 4.94958ZM13.0446 6.12792L10.2496 8.9225C10.0925 9.08 9.88333 9.16667 9.66042 9.16667H9.41625V8.9225C9.41625 8.7 9.50292 8.49083 9.66042 8.33333L12.4554 5.53875C12.6125 5.38125 12.8871 5.38125 13.0446 5.53875C13.1233 5.61708 13.1667 5.72167 13.1667 5.83333C13.1667 5.945 13.1233 6.04917 13.0446 6.12792Z" fill="#FF8C00"/>
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_545_3381">
                    <rect width="28" height="28" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_545_3381">
                    <rect width="10" height="10" fill="white" transform="translate(4)"/>
                    </clipPath>
                    </defs>
                  </svg>

                  </div>
                  <span className="text-buddy-text-1 text-start font-medium text-sm grow pointer-events-none">Ediar nombre</span>
                  <ChevronRightIcon className="size-4 pointer-events-none"></ChevronRightIcon>
                </button>
              </div>
          </div>

        }

        {
          showEditForm &&
          <div className="absolute top-0 left-0 w-full h-screen text-white bg-black bg-opacity-60">
              <div className="mt-40 mx-8 rounded-lg my-auto  border-t flex flex-col space-y-3 border-buddy-bg-2 bg-secondary-bg p-4">
                <div className="flex justify-end">
                  <button onClick={ (e) => { setShowEditForm(false) }} className="p-1">
                    <XMarkIcon className="size-5" />
                  </button>
                </div>
                <InputState name="name" label="Nombre del organizador" value={menuOrganizer?.name} />
                <PrimaryButton onClick={handleOnUpdate}>Guardar</PrimaryButton>
              </div>
          </div>
        }

        <div className='w-full h-12  flex justify-start text-title items-center text-lg mb-2'>
          <div className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
              <button onClick={ handleOnBack } className='text-title'><FontAwesomeIcon icon={faChevronLeft} /></button>
          </div>
          <div className='grow text-center'>
              <div className='font-bold '>{
              
              week !== null ? week[0].date_label + " - " + week[week.length-1].date_label : "Organizador de menús"
              
              }</div>
          </div>
          
          <button className={ week !== null ? 'hidden' : '' } onClick={ (e) => {  setShowMenu(!showMenu) } }>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_543_3313)">
            <rect x="6" width="4" height="4" rx="2" fill="white"/>
            <rect x="6" y="6" width="4" height="4" rx="2" fill="white"/>
            <rect x="6" y="12" width="4" height="4" rx="2" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_543_3313">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
            </svg>
          </button>
        </div>
        
        <div>
            { 
              menuOrganizer && week === null &&

              <div>
                <div>
                  <img className="w-full" src={image} />
                </div>
                <div className="text-buddy-text-1 font-medium text-lg text-center px-2 py-4 flex flex-col">{ menuOrganizer.name }</div>

                {
                  menuOrganizer.weeks.map((week, i) => {
                      return (
                          <button onClick={ (e) => { handleOnSelectWeek(i) }} key={i} className="w-full text-start bg-form-control-bg px-6 py-2 flex justify-between items-center rounded-lg mb-2">
                            <div className="flex flex-col">
                              <span className="text-buddy-text-1 font-medium">Semana { i + 1}</span>
                              <span className="text-buddy-text-6 font-semibold">{ week[0].date_label } - { week[week.length - 1].date_label }</span>
                            </div>
                            <ChevronRightIcon className="size-5 text-buddy-text-6"></ChevronRightIcon>
                          </button>
                      )
                  })
              }

              </div>
            }
            {
              menuOrganizer && week !== null && 

              <div className="w-full mt-16">
                {
                  week.map((day, i) => {
                      return (
                          <button onClick={ (e) => { navigate("/menuOrganizerDayShow?id="+ id + "&date=" + day.date + "&date_label=" + day.date_label ) }} key={i} className="w-full text-start bg-form-control-bg px-6 py-4 flex justify-between items-center rounded-lg mb-2">
                            <div className="flex flex-col pointer-events-none">
                              <span className="text-buddy-text-1 font-medium pointer-events-none">{ day.label }</span>
                            </div>
                            <ChevronRightIcon className="size-5 text-buddy-text-6 pointer-events-none"></ChevronRightIcon>
                          </button>
                      )
                  })
              }

              </div>
            }


        </div>
      </div>
    </div>
  );
};

export default MenuOrganizerShow;
