import React from 'react'
import { Link } from 'react-router-dom'

const DashboardItem = ({ label, iconSrc, to }) => {
    return (
        <>
            <Link className='flex justify-center p-0 m-0' to={to}>
                <div className='flex flex-col'>
                    <div className='w-full flex justify-center'>
                        <div className="w-24 h-24 bg-buddy-bg-2 rounded-full flex flex-col items-center justify-center space-y-1">
                            <img className="" src={iconSrc} />
                        </div>
                    </div>
                    <div className="w-full px-4 mt-2 font-semibold text-sm  text-buddy-text-1">{label}</div>
                </div>
            </Link>
        </>
    )
}
export default DashboardItem 