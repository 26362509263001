import React from 'react';

const Contact = () => {
  return (
  <div>
    Contacta con nosotros aquí:<br />
    <a href="mailto:info@buddymarket.com">info@buddymarket.com</a>
  </div>
  );
};

export default Contact;