import React from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 
import ensaladillaRusa from "../img/ensaladilla-rusa.png";
import ensaladillaGriega from "../img/ensaladilla-griega.png";
import FooterMain from './FooterMain';

const RecetasGuardadasTodas = () => {
  return (
  <div className="contenedor">
     <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Recetas guardadas</h2>      
    </div>
    <div className="rectangulo centrado busqueda">
      <form id="busqueda" action="buscar">
        <div className="input-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
          <input className="w100" type="text" name="user" placeholder="Buscar en BuddyMarket" /><br />
        </div>
      </form>
    </div>
   <div className="rectangulo round mb20 relative fondooscuro">
        <div className='flex wrap equiespaciado'>
          <div className='flex wrap cgap20'>
            <a className="w50 centrado block" href="#"><img src={ensaladillaRusa} /><span className="minititulo dentro">Desayunos</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaGriega} /><span className="minititulo dentro">Griega</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaRusa} /><span className="minititulo dentro">Pasta</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaGriega} /><span className="minititulo dentro">Crudivegana</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaRusa} /><span className="minititulo dentro">Keto</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaGriega} /><span className="minititulo dentro">Purés</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaRusa} /><span className="minititulo dentro">Carnes Rojas</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaGriega} /><span className="minititulo dentro">Vegetariana</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaRusa} /><span className="minititulo dentro">Rusa</span></a>
            <a className="w50 centrado block" href="#"><img src={ensaladillaGriega} /><span className="minititulo dentro">Griega</span></a>
          </div>
        </div>
      </div>
      <br /><br />
      <FooterMain />
  </div>);
};

export default RecetasGuardadasTodas;