import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PasswordWithValidationInput = ({ name, label, type = 'text', placeholder='', onValidityChange , onChange }) => {

    const [inputValue, setInputValue] = useState('');
    const inputReference = useRef(null);

    const [validLength, setValidLength] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [validSpecial, setValidSpecial] = useState(false);

    // Check and update the validity
    useEffect(() => {
        const isValid = validLength && validNumber && validSpecial;
        if (onValidityChange) {
            onValidityChange(isValid);
        }
    }, [validLength, validNumber, validSpecial, onValidityChange]);

    const resetButtonClassName = inputValue.length > 0 ? 'absolute right-0 bottom-0 top-0' : 'hidden absolute right-0 bottom-0 top-0';

    const validationLengthClassName = validLength ? 'text-primary' : 'text-white';
    const validationNumberClassName = validNumber ? 'text-primary' : 'text-white';
    const validationSpecialClassName = validSpecial ? 'text-primary' : 'text-white';

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        validate(value);
        if (onChange) {
            onChange(value);
        }
    };

    const handleResetInput = () => {
        setInputValue('');
        validate('');
        inputReference.current.focus();
    };

    const validate = (value) => {
        setValidLength(value.length >= 8);
        const hasNumber = /\d/;
        setValidNumber(hasNumber.test(value));
        const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};:\\"|,.<>\/?~]/;
        setValidSpecial(hasSpecial.test(value));
    };

    return (
        <>
            <div className='w-full'>
                <label className="text-white font-semibold text-md" htmlFor={name}>{label}</label><br />
                <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1 mt-2">
                    <div className={resetButtonClassName}>
                        <button type='button' onClick={handleResetInput} className='flex items-center justify-center w-8 me-2 h-full'>
                            <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                                <FontAwesomeIcon className='text-white' icon={faTimes} />
                            </div>
                        </button>
                    </div>
                    <input value={inputValue} onChange={handleInputChange} ref={inputReference} type='password' autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-4 pe-16 placeholder:font-light rounded-xl"
                        name={name}
                        placeholder={placeholder}
                    />
                </div>
                <div className='py-4 ps-2'>
                    <div className='flex justify-start items-center gap-2'>
                        <span className='text-lg'><FontAwesomeIcon className={validationLengthClassName} icon={faCheckCircle} /></span><span className='text-white text-sm'>Al menos 8 caracteres</span>
                    </div>
                    <div className='flex justify-start items-center gap-2'>
                        <span className='text-lg'><FontAwesomeIcon className={validationNumberClassName} icon={faCheckCircle} /></span><span className='text-white text-sm'>Contiene un número</span>
                    </div>
                    <div className='flex justify-start items-center gap-2'>
                        <span className='text-lg'><FontAwesomeIcon className={validationSpecialClassName} icon={faCheckCircle} /></span><span className='text-white text-sm'>Contiene un carácter especial</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordWithValidationInput;