import React, { useEffect, useState } from 'react';
import Toolbar from "./ui/Toolbar.js";
import { useNavigate } from 'react-router-dom';
import FoodCategoryTile from "./ui/FoodCategoryTile";
import FoodCategory from "../api/services/FoodCategory";

const FoodCategoryList = () => {
  const [foodCategories, setFoodCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await FoodCategory.list();
        setFoodCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={'/recipes'}>
          <div className=''>
            <span>Categorías principales</span>
          </div>
        </Toolbar>
        <div className="flex flex-col space-y-3">
            <div className="mx-auto w-full">
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {foodCategories.map(foodCategory => (
                    <FoodCategoryTile 
                      width={'100%'} 
                      height={'100%'} 
                      foodCategory={foodCategory} 
                      key={foodCategory.id} 
                      to={`/recipesSearch?food_category_id=${foodCategory.id}`} 
                    />
                  ))}
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FoodCategoryList;