import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Input from './forms/Input';
import Toggle from './forms/Toggle';
import MeasureInput from './forms/MeasureInput';
import UserWeightType from '../api/services/UserWeightType';

const ProfileForm15 = () => {
  const navigate = useNavigate();

  const [weightTypeId, setWeightTypeId] = useState('');
  const [weightTypeOptions, setWeightTypeOptions] = useState([]);
  const [weight, setWeight] = useState('');
 
  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.user_weight_type_id = weightTypeId;
    data.user_weight_type_label = weightTypeId;
    data.weight = weight;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm16');
  }

  const handleOnChangeWeightTypeId = (value) => {
    setWeightTypeId(value);
  }

  const handleOnChangeWeight = (value) => {
    setWeight(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    loadWeightTypeOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setWeightTypeId(data.user_weight_type_id ?? 1);
      setWeight(data.weight ?? '');
    }
  }, []);

  async function loadWeightTypeOptions() {
    try {
      const response = await UserWeightType.list();
      var options = [];
      response.data.map(type => {
        options.push({
          value: type.id,
          label: type.name
        });
      });
      setWeightTypeOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm14"} replace={true}>
              <div className='text-sm'>
                <span >Paso 15</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Peso <span className='text-primary'>(opcional)</span></h2>
            <div className='w-2/3 mt-6'>
              <Toggle onChange={handleOnChangeWeightTypeId} selected={weightTypeId} options={weightTypeOptions} />
            </div>
            <div className='my-2'></div>
            <MeasureInput id="weight" value={weight} onChange={handleOnChangeWeight} name="weight" type="number"></MeasureInput>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm15;