import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import instagramIcon from '../icons/instagram.png';
import linkedinIcon from '../icons/linkedin.png';
import youtubeIcon from '../icons/youtube.png';

const UserNotificationsSelect = () => {
    const navigate = useNavigate();
  
    useEffect(() => {}, []);
  
    return (
      <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={"/sidebarMenu"}>Notificaciones</Toolbar>
          <form className='h-full w-full mt-10'>
            <div className='flex flex-col flex-start items-center gap-3'>
                <div className='w-full flex justify-between items-center bg-buddy-bg-2 p-3 rounded-2xl'>
                  <span className="text-buddy-text-1 mt-auto mb-auto font-semibold grow-0">Notificaciones dentro de la APP</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" defaultChecked />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[rgb(255,140,0)] peer-checked:text-opacity"></div>
                  </label>
                </div>
                <div className='w-full flex justify-between items-center bg-buddy-bg-2 p-3 rounded-2xl'>
                  <span className="text-buddy-text-1 mt-auto mb-auto font-semibold grow-0">Newsletter <span className="text-primary">(Correo electrónico)</span></span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" defaultChecked />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[rgb(255,140,0)] peer-checked:text-opacity"></div>
                  </label>
                </div>
            </div>
          </form>
        </div>
      </div>
    );
};
  
export default UserNotificationsSelect;
