import React from 'react'
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline'

const ReadMoreTile = ({ to }) => {
    return (
        <>
            <Link to={to} className="flex items-center justify-center w-28 mx-6  mb-2 rounded-lg">
                <div className="flex items-center justify-center w-12 h-12 rounded p-2 bg-buddy-bg-3">
                    <PlusIcon className='size-6 text-primary grow-0' />
                </div>
            </Link>
        </>
    )
}

export default ReadMoreTile 