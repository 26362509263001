import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar.js';
import FooterToolbar from './ui/FooterToolbar.js';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput.js';
import Input from './forms/Input.js';
import PrimaryButton from './ui/PrimaryButton.js';
import passwordChangedImage from '../img/password_changed.png';
import MeasureInput from './forms/MeasureInput.js';
import User from "../api/services/User.js";

const UserUpdatePassword = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const [currentStep, setCurrentStep] = useState(1);
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    sendResetMail();
    const el = document.querySelector('input[name="code1"]');
    if (el) {
      el.focus();
    }
  }, []);

  const sendResetMail = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await User.createAuthToken(user.id);
    } catch (error) {
      console.error('Error sending mail:', error);
    }
  };

  const updatePassword = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const code = `${code1}${code2}${code3}${code4}`;
      const data = {
        code: code,
        password: newPassword, 
      };
      await User.updatePassword(user.id, data);
      setCurrentStep(3);
    } catch (error) {
      console.error('Error updating password:', error);
      setCurrentStep(4);
    }
  };

  const isCodeComplete = () => {
    return [code1, code2, code3, code4].every(code => code.length === 1);
  };

  const handleOnChangeCode1 = (value) => {
    setCode1(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code2"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode2 = (value) => {
    setCode2(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code3"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode3 = (value) => {
    setCode3(value);
    if (value && value.length === 1) {
      const nextInput = document.querySelector('input[name="code4"]');
      if (nextInput) nextInput.focus();
    }
  };

  const handleOnChangeCode4 = (value) => {
    setCode4(value);
  };

  const handleCheckCode = (e) => {
    if (isCodeComplete()) {
      setCurrentStep(2);
    }
  };

  const handleChangePassword = (e) => {
    if (isPasswordValid && newPassword === verifyPassword) {
      updatePassword();
    }
  };

  const handleGoToIndex = (e) => {
    navigate('/');
  };

  return (
    <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
      {currentStep === 1 && (
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={'/security'}>Cambiar contraseña</Toolbar>
          <form className='w-full'>
            <div className='flex flex-col bg-red items-center gap-3'>
              <h2 className='w-full text-center text-white text-2xl font-semibold'>
                Consulta tu correo electrónico
              </h2>
              <p className='text-buddy-text-3 text-center font-semibold'>
                Hemos enviado el código a tu correo electrónico.
              </p>
              <h2 className='w-full text-left text-white text-sm font-semibold mt-8'>
                Introduce el código
              </h2>
              <div className='flex justify-evenly w-100 gap-3'>
                <MeasureInput
                  id='code1'
                  value={code1}
                  name='code1'
                  type='number'
                  onChange={handleOnChangeCode1}
                />
                <MeasureInput
                  id='code2'
                  value={code2}
                  name='code2'
                  type='number'
                  onChange={handleOnChangeCode2}
                />
                <MeasureInput
                  id='code3'
                  value={code3}
                  name='code3'
                  type='number'
                  onChange={handleOnChangeCode3}
                />
                <MeasureInput
                  id='code4'
                  value={code4}
                  name='code4'
                  type='number'
                  onChange={handleOnChangeCode4}
                />
              </div>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type='button' onClick={handleCheckCode} disabled={!isCodeComplete()}>
              Cambiar contraseña
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 2 && (
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={'/security'}>Cambiar contraseña</Toolbar>
          <form className='w-full'>
            <div className='flex flex-col bg-red items-center gap-3'>
              <PasswordWithValidationInput
                name='password'
                label='Introduce tu nueva contraseña'
                placeholder=''
                onValidityChange={setIsPasswordValid}
                onChange={setNewPassword}
              />
              <div className='w-full'>
                <Input
                  name='password_repeat'
                  type='password'
                  label='Repita su nueva contraseña'
                  placeholder=''
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)} 
                />
              </div>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton
              type='button'
              onClick={handleChangePassword}
              disabled={!isPasswordValid || newPassword !== verifyPassword}
            >
              Guardar cambios
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 3 && (
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={'/security'}>Cambiar contraseña</Toolbar>
          <form className='w-full'>
            <div className='flex flex-col bg-red items-center gap-3'>
              <img
                style={{ width: '200px' }}
                src={passwordChangedImage}
                alt='Password Changed'
              />
              <h2 className='w-full text-center text-white text-xl font-semibold'>
                ¡ Contraseña cambiada !
              </h2>
              <p className='text-buddy-text-3 text-center font-semibold'>
                Tu contraseña ha sido cambiada exitosamente
              </p>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type='button' onClick={handleGoToIndex}>
              Volver a BuddyMarket
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
      {currentStep === 4 && (
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={'/security'}>Cambiar contraseña</Toolbar>
          <form className='w-full'>
            <div className='flex flex-col bg-red items-center gap-3'>
              <img
                style={{ width: '200px' }}
                src={passwordChangedImage}
                alt='Error changing password'
              />
              <h2 className='w-full text-center text-white text-xl font-semibold'>
                ERROR
              </h2>
              <p className='text-buddy-text-3 text-center font-semibold'>
                Ha ocurrido un error al modificar la contraseña, vuelve a intentarlo
              </p>
            </div>
          </form>
          <FooterToolbar>
            <PrimaryButton type='button' onClick={handleGoToIndex}>
              Volver a BuddyMarket
            </PrimaryButton>
          </FooterToolbar>
        </div>
      )}
    </div>
  );
};

export default UserUpdatePassword;