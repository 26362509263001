import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Gender from '../api/services/Gender';

const ProfileForm2 = () => {
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [genderId, setGenderId] = useState('');

  const handleClick = (e) => {
    if (genderId !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.gender_id = genderId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm3');
    } else {
      alert('Establecer género para continuar');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setGenderId(data.gender_id ?? '');
    }
    loadGenders();
  }, []);

  const handleOnChange = (value) => {
    setGenderId(value);
  }

  async function loadGenders() {
    try {
      const response = await Gender.list();
      var gendersOptions = [];
      response.data.map(gender => {
        gendersOptions.push({
          value: gender.id,
          label: gender.name
        });
      });
      setOptions(gendersOptions);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar  to={"/profileForm1"} replace={true}>
              <div className='text-sm'>
                <span >Paso 2</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cuál es tu género?</h2>
            <SelectList onChange={handleOnChange} selected={genderId} options={options} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm2;