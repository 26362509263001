import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import SelectList from './forms/SelectList';
import Textarea from './forms/Textarea';

const ProfileForm25 = () => {
  const navigate = useNavigate();

  const [hasSurgery, setHasSurgery] = useState('');
  
  const options = [
    {
      value: 1,
      label: "Sí"
    },
    {
      value: 0,
      label: "No"
    }
  ]

  const handleClick = (e) => {
    if (hasSurgery !== '') {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.has_surgery = hasSurgery;
      data.surgery = document.querySelector('[name="surgery"]').value;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm26');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setHasSurgery(data.has_surgery ?? '');
      const el = document.querySelector('[name="surgery"]');
      if (el) {
        el.value = data.surgery ?? '';
      }
    }
  }, []);

  const handleOnChangeHasSurgery = (value) => {
    setHasSurgery(value);
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm24"} replace={true}>
              <div className='text-sm'>
                <span >Paso 25</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Te han realizado alguna cirugía que afecte tu alimentación o actividad física?</h2>
            <SelectList onChange={handleOnChangeHasSurgery} selected={hasSurgery} options={options} />
            
            <div className='my-3'></div>
            <h2 className='w-full text-white text-lg font-semibold'>Si la respuesta es sí, por favor especifica:</h2>
            <Textarea name='surgery' />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm25;