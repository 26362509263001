import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logobuddy from '../img/logo-naranja.png';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const RecCont = () => {

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
              <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Restablecer Contraseña</h2>      
      </div>
      <div className="rectangulo centrado fondoinicio round mb20">
        <h1>¿Has olvidado tu contraseña?</h1>
        <p>¡No te preocupes, te cubrimos!<br />¡Manda tu correo electrónico registrado y te enviaremos un enlace para restablecer la contraseña!</p>
      </div>
      <form className="izquierda" action="recuperar-contrasena2">
      <div className="rectangulo centrado round">
        <label className="izquierda w100 mb10" htmlFor="user">Correo electrónico</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <input className="w100 mb20" type="text" name="email" placeholder="Correo electrónico" /><br />
        </div>
      </div>
      <div className="">
          <button className="w100 submit fondonaranja" type="submit">Enviar</button>
        </div>
      </form>
      <div className="contenedorabajo centrado">
        <img className="w60 inlineblock" src={logobuddy} alt="Logo BuddyMarket" />
      </div>

    </div>
  );
};

export default RecCont;