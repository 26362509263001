import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Recipe from "../api/services/Recipe.js";
import User from "../api/services/User.js";
import RecipeIngredient from "../api/services/RecipeIngredient.js";
import Toolbar from "./ui/Toolbar.js";
import { PencilIcon, TrashIcon, PlusIcon } from "@heroicons/react/24/outline";


const RecipeEditIngredients = () => {

  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [recipe, setRecipe] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = searchParams.get('id') ?? '';

  useEffect(() => {    
    getRecipe(id);
  }, []);

  const getRecipe = async (id) => {
    try {
      const response = await User.recipeGet(user.id, id);
      const recipe = response.data;
      setRecipe(recipe);
    } catch (error) {
      console.error('Error fetching author:', error);
    }
  };


  const handleOnDelete = (e, ingredientId) => {
    if (window.confirm("¿Seguro que quieres borrar este ingrediente?") == true) {
      try {
        RecipeIngredient.delete(id, ingredientId);
        getRecipe(id);
      } catch (error) {
        alert(error.response.payload.message ?? 'Error inesperado. Inténtalo de nuevo.');
        console.error('Error deleting ingredient:', error);
      }
    }
  }

  const handleOnEdit = (e, ingredientId) => {
    navigate("/recipeIngredientEdit?recipe_id="+ id + "&ingredient_id=" + ingredientId);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to="/recipeList" replace={true}>
          <div className='text-md'>
            <span >Editar Receta</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-2 space-y-3">

          <div className='w-full'>
              <div className="w-full flex bg-black bg-opacity-40 rounded-lg gap-3 p-1">
                <Link  to={"/recipeEdit?id=" + id } replace={true} className="w-full text-center text-buddy-text-4 py-3 px-2 text-md rounded-lg bg-transparent">General</Link>
                <Link to={"/recipeEditIngredients?id=" + id } replace={true} className="w-full text-primary text-center py-3 px-2 text-md rounded-lg bg-buddy-bg-3">Ingredientes</Link>
                <Link to={"/recipeEditSteps?id=" + id } replace={true} className="w-full text-center text-buddy-text-4 py-3 px-2 text-md rounded-lg bg-transparent">Instrucciones</Link>
              </div>
          </div>

          <div className="flex flex-col space-y-3">
            <div className="flex justify-end space-x-4 mt-2">
              <button onClick={ (e) => { navigate('/recipeIngredientCreate?recipe_id=' + id) }}>
                <div className="flex items-center pointer-events-none space-x-2 ring-1 text-primary ring-primary rounded-md p-2">
                  <PlusIcon className='pointer-events-none size-5 ' /><span>Añadir ingrediente</span>
                </div>
              </button>
            </div>
            {
              recipe?.recipeIngredients.map((recipeIngredient, i) => {
                  return (
                    <div key={recipeIngredient.id}>
                      <div className="w-full flex flex-col text-sm bg-opacity-80 rounded-lg p-4 mt-1 bg-buddy-bg-2 text-buddy-text-1" >
                        <div>{ recipeIngredient.quantity } {recipeIngredient.measure.name } { recipeIngredient.ingredient.name }</div>
                        <div className="flex justify-end space-x-4 mt-2">
                          <button onClick={ (e) => { handleOnEdit(e, recipeIngredient.id) }}>
                            <div className="pointer-events-none ring-1 ring-primary rounded-md p-2">
                              <PencilIcon className='pointer-events-none size-5 text-primary' />
                            </div>
                          </button>
                          <button onClick={ (e) => { handleOnDelete(e, recipeIngredient.id) }} className="text-buddy-text-danger">
                            <div className="pointer-events-none ring-1 ring-buddy-text-danger rounded-md p-2">
                              <TrashIcon className='pointer-events-none size-5 text-buddy-text-danger' />
                            </div>
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  )
              })
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeEditIngredients;
