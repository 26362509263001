import React from 'react'
import { Link } from 'react-router-dom'

const NewsletterCheckbox = ({ name }) => {


    return (
        <>
            <div className="flex gap-2 text-white">
                <input name={name} type="checkbox" className="shrink-0 rounded-sm border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow " />
                <div className="">
                    <label className="block text-sm">
                        Acepto recibir comunicaciones y/o newsletter con información acerca de los servicios de BuddyMarket conforme su <Link className='text-primary' to=''>Política de Privacidad</Link>.
                    </label>
                </div>
            </div>
        </>
    )
}
export default NewsletterCheckbox 