import ky from "ky";

const client = ky.extend({
  prefixUrl: 'https://app.buddymarket.io/api/',
  //prefixUrl: 'http://backend.buddymarket.homestead/api/',
  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem('token');
        request.headers.set('Authorization', `Bearer ${token}`);
      }
    ],
    beforeError: [
        error => {
            const {response} = error;
            if (response && response.body) {
                error.name = "Error";
                error.code = response.status;
            }
            return error;
        }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          response.payload = await response.json();
          return response;
        }
      }
    ]
  }
});

export default client;