import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 
import FooterMain from './FooterMain';


const Categorias = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://buddymarket.io/dummy/apibm/recipes/readcategories.php')
      .then(response => response.json())
      .then(data => {
        setCategories(data.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  return (  <div className="contenedor">
     <div className="titulin">
      <Link to={-1}>
            <span className="simbologran">&lt;</span>
      </Link>
      <h2 className="cuerpotexto">Categorías</h2>      
    </div>
    <div className="rectangulo centrado busqueda">
      <form id="busqueda" action="buscar">
        <div className="input-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
          <input className="w100" type="text" name="user" placeholder="Buscar" /><br />
        </div>
      </form>
    </div>
   <div className="rectangulo round mb20 relative fondooscuro">
        <div className='flex wrap equiespaciado'>
          <div className='flex wrap cgap20'>
            
            {categories.map(category => (
              <a key={category.id} className="w50 centrado block" href={`categoria/?id=${category.id}`}>
                <img src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/categorias/${category.foto}`} alt={category.name} />
                <span className="minititulo dentro">{category.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <br /><br />
      <FooterMain />
  </div>);
};

export default Categorias;