import React from 'react'

const SmallToggle = ({ selected, disabled = false, label = '', onChange = null, options = [] }) => {

    const handleOptionSelected = (e) => {
        if (!disabled) {
            const value = parseInt(e.target.dataset.value);
            if (onChange != null) {
                onChange(value);
            }
        }
    }

    return (
        <>
            <div className='w-full'>
                {
                    label != null ? <p className="text-white font-semibold text-md mb-2" htmlFor="email">{label}</p> : ''
                }
                <div className="w-full flex bg-black bg-opacity-40 rounded-lg gap-3 p-1">
                    {
                        options.map(option => {
                            const {value, label} = option;
                            const optionsClassName = selected === value ? 'w-full text-primary text-center py-3 px-2 text-md rounded-lg bg-buddy-bg-3' : 'w-full text-center text-buddy-text-4 py-3 px-2 text-md rounded-lg bg-transparent';
                            return (
                                <button type='button' onClick={handleOptionSelected} key={value} className={optionsClassName} data-value={value}>{label}</button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default SmallToggle 