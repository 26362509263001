import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Recipe from "../api/services/Recipe.js";
import RecipeTile from "./ui/RecipeTile.js";
import Card from "./ui/Card.js";
import { useSearchParams } from "react-router-dom";
import User from "../api/services/User.js";
import FoodCategory from "../api/services/FoodCategory.js";
import Toolbar from "./ui/Toolbar.js";

const RecomendedRecipes = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [noResults, setNoResults] = useState(false);

  const userId = searchParams.get('user_id') ?? '';
  const foodCategoryId = searchParams.get('food_category_id') ?? '';

  const [author, setAuthor] = useState(null);
  const [foodCategory, setFoodCategory] = useState(null);

  useEffect(() => {    
    if (userId != '') {
      getUser(userId);
    }
    if (foodCategoryId != '') {
      getFoodCategory(foodCategoryId);
    }
    setSearch(searchParams.get('search') ?? '');
    
  }, []);

  const getUser = async (userId) => {
    try {
      const response = await User.get(userId);
      setAuthor(response.data);
    } catch (error) {
      console.error('Error fetching author:', error);
    }
  };

  const getFoodCategory = async (foodCategoryId) => {
    try {
      const response = await FoodCategory.get(foodCategoryId);
      setFoodCategory(response.data);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getRecipes = async () => {
    setNoResults(false);
    setRecipes([]);
    try {
      const response = await Recipe.list({
        search: search,
        user_id: userId,
        food_category_id: foodCategoryId,
        recomended: true
      });
      if (response.data.length == 0) {
        setNoResults(true);
      }
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  useEffect(() => {
    const to = setTimeout(() => {
      getRecipes();
    }, 1000);
    return () => clearTimeout(to);
  }, [search]);

  const handleResetSearch = (e) => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    setNoResults(false);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={'/recipes'}>
          <div className=''>
            <span>Te podría gustar</span>
          </div>
        </Toolbar>
        <div className="flex flex-col space-y-3">
          {
            author ? <p className="text-buddy-text-1">Mostrando recetas de <span className="font-semibold">'{author.name}'</span></p> : ''
          }
          {
            foodCategory ? <p className="text-buddy-text-1">Mostrando categoría <span className="font-semibold">'{foodCategory.name}'</span></p> : ''
          }

        { noResults && search.length > 0 &&
          <p className="text-buddy-text-1">No se encontraron recetas que cumplan con los criterios de búsqueda.</p>
        }

          <div className="mx-auto w-full">
            <div className="grid grid-cols-1 gap-3 mt-2">
              {
                  recipes.map(recipe => {
                      return (
                        <Card  key={recipe.id} className="bg-buddy-bg-2" >
                          <RecipeTile recipe={recipe} key={recipe.id} to={ "/recipe?id=" + recipe.id } />
                        </Card>
                      )
                  })
              }
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RecomendedRecipes;
