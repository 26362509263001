import React, { useEffect, useState } from "react";
import { getUserInfo } from "../auth";
import { useForm } from "react-hook-form";
import { useNavigate, redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import RecetaIngrE from "./RecetaIngrE";
import RecetaInstrE from './RecetaInstrE';

const NuevaReceta = () => {
  
  const [receta, setReceta] = useState([]);
  const [recetaIngredients, setRecetaIngredients] = useState([]);
  const [result, setResult] = useState([]);
  const userinfo = getUserInfo();
  const navigate = useNavigate();
  const { register, formState, handleSubmit, watch, setValue, onChange } =
    useForm();
  const paginaperfil = "../perfil/" + userinfo.id;
  const [mostrarCapa1, setMostrarCapa1] = useState(true);
  const [capaVisible, setCapaVisible] = useState("capa1");
  const [file, setFile] = useState();

  function fotopreview(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  function handleclick(e) {
    document.getElementById("fotoprincipal").click();
  }

  const onSubmit = (data) => {
    /*console.log(data['name']);*/

    const formData = new FormData();
    var input = document.querySelector('input[type="file"]');
    for (const file of input.files) {
      formData.append("photos", file, file.name);
    }
    formData.append("name", data["name"]);
    formData.append("description", data["description"]);
    formData.append("category", data["category"]);
    formData.append("active", data["active"]);
    formData.append("cook_timeh", data["cook_timeh"]);
    formData.append("cook_timem", data["cook_timem"]);
    formData.append("preparation_timeh", data["preparation_timeh"]);
    formData.append("preparation_timem", data["preparation_timem"]);
    formData.append("rations", data["rations"]);
    formData.append("author", data["author"]);
    formData.append("photo", data["photo"]);

    const requestOptions = {
      method: "POST",
      redirect: "manual",
      /*headers: { 'Content-Type': 'application/json' },*/
      /*body: JSON.stringify(formData)*/
      body: formData,
    };

    fetch(
      "https://buddymarket.io/dummy/apibm/recipes/create.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        navigate("/editar-receta/" + data.last_id);
      })
      .catch((error) => console.error("Error creating recipe:", error));
  };

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Añadir receta</h2>
      </div>

      <form className="izquierda" onSubmit={handleSubmit(onSubmit)}>
        <div className="rectangulo centrado round mt0">
          <label className="izquierda w100 mb10" htmlFor="name">
            Nombre de la receta
          </label>
          <br />
          <div className="input-container2">
            {/*<FontAwesomeIcon icon={faCircleInfo} className="iconright" />*/}
            <input
              className="w100 mb20"
              type="text"
              name="name"
              onBlur={handleSubmit(onSubmit)}
              {...register("name", { required: true, onBlur: handleSubmit(onSubmit) })}
            />
            <br />
            {formState.errors.name?.type === "required" && (
              <p className="titulin naranja mb10">El campo es requerido</p>
            )}
          </div>

          <label className="izquierda w100 mb10" htmlFor="raciones">
            Categoría
          </label>
          <br />
          <div className="instruccionesreceta mb10">
            <div className="flex w100">
              <label className="izquierda mb10">
                <select
                  name="category"
                  className="w100"
                  {...register("category")}>
                  <option value="1">Española</option>
                  <option value="2">Italiana</option>
                  <option value="3">Japonesa</option>
                  <option value="4">Coreana</option>
                </select>
              </label>
            </div>
          </div>

          <label className="izquierda w100 mb10" htmlFor="photo">
            Foto
          </label>

          <br />
          <div className="input-container">
            <button
              id="botonfotos"
              type="button"
              className="botonfotos mb20"
              onClick={handleclick}>
              <img className="w100" id="imgprincipal" src={file} />
              <FontAwesomeIcon icon={faCamera} />
              <br />
              Añadir/Cambiar foto de portada
            </button>

            <input
              id="fotoprincipal"
              className="w100 mb20"
              style={{ position: "absolute", bottom: "9999px", left: "0" }}
              type="file"
              name="photo"
              {...register("photo")}
              onChange={(e) => {
                fotopreview(e);
              }}/>
            <br />
          </div>

          <label className="izquierda w100 mb10" htmlFor="description">
            Descripción
          </label>
          <br />
          <div className="input-container">
            <textarea
              className="w100 mb20"
              rows="4"
              name="description"
              {...register("description")}
            />
            <br />
          </div>
          <br /> 
        <h3 className="izquierda w100 mb10">Ingredientes</h3>
      
        <RecetaIngrE 
              idreceta={null}
            />
        <br />
        <h3 className="izquierda w100 mb10">Instrucciones</h3>
        <br />

        <RecetaInstrE
              idreceta={null}
            />
        <br />

          <label className="izquierda w100 mb10" htmlFor="rations">
            Raciones
          </label>
          <br />
          <div className="instruccionesreceta mb10">
            <div className="w100 mb10 izquierda">
              Esto se utiliza para calcular las raciones en base a las
              cantidades y calcular el valor nutricional de la receta.
            </div>
            <div className="flex centrado w100">
              <label className="mb10">
                <select
                  name="rations"
                  defaultValue={2}
                  {...register("rations")}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </label>
            </div>
          </div>

          <label className="izquierda w100 mb10" htmlFor="cook_timeh">
            Tiempo de coccion
          </label>
          <br />
          <div className="tiempococcion mb10">
            <div className="w100 mb10 izquierda">
              ¿Cuanto tiempo tiene que cocinarse?
            </div>
            <div className="flex centrado w100">
              <label className="mb10">
                Horas
                <br />
                <select name="cook_timeh" {...register("cook_timeh")}>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label className="mb10">
                Minutos
                <br />
                <select name="cook_timem" {...register("cook_timem")}>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                </select>
              </label>
            </div>
          </div>

          <label className="izquierda w100 mb10" htmlFor="preparation_timeh">
            Tiempo de preparación
          </label>
          <br />
          <div className="tiempopreparacion mb10">
            <div className="w100 mb10 izquierda">
              ¿Cuanto tiempo se tarda en preparar?
            </div>
            <div className="flex centrado w100">
              <label className="mb10">
                Horas
                <br />
                <select
                  name="preparation_timeh"
                  {...register("preparation_timeh")}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label className="mb10">
                Minutos
                <br />
                <select
                  name="preparation_timem"
                  {...register("preparation_timem")}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                </select>
              </label>
            </div>
          </div>

          <label className="centrado w100 mb10" htmlFor="active">
            Publicar
          </label>
          <br />
          <div className="instruccionesreceta mb10">
            <div className="flex centrado w100">
              <label className="centrado mb10">
                <select
                  name="active"
                  className="w100"
                  defaultValue={0}
                  {...register("active")}
                >
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </label>
            </div>
          </div>
          <input
            type="hidden"
            name="author"
            value={userinfo.id}
            {...register("author")}
          />
        </div>
        <div className="">
          <button className="w100 submit fondonaranja" type="submit">
            Guardar receta
          </button>
        </div>
      </form>
    </div>
  );
};

export default NuevaReceta;
