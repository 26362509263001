import React from 'react';
import { useState } from 'react';
import gracias from '../img/gracias.png'; 

const RecContFin = () => {

  return (
    <div className="contenedor">
      <div className="rectangulo centrado h60">
        
      </div>
      <div className="rectangulo centrado fondouno round">
      <img className="gracias" src={gracias} alt="Gracias por registrarte" />
      </div>
      <div className="rectangulo centrado round mb20">
        <h1 className="naranja">¡Contraseña cambiada!</h1>
        <p>Tu contraseña ha sido cambiada con éxito</p>
      </div>
      <form action="./">
        <div className="">
          <button className="w100 submit fondonaranja" type="submit">Iniciar sesión</button>
        </div>
      </form>
    </div>
  );
};

export default RecContFin;