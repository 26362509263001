import React from 'react';
import { useState, useEffect  } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 

import FooterMain from './FooterMain';

const Categoria = () => {

  const [categoria, setCategoria] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const { id } = useParams();
 
  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readlist.php?category=${id}&limit=all`)
      .then(response => response.json())
      .then(data => {
        setRecetas(data.data);
      })
      .catch(error => console.error('Error fetching recipes:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readcategory.php/?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setCategoria(data.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  return (
  <div className="contenedor">
     <div className="titulin">
        <Link to={-1}>
            <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Categoría</h2>      
    </div>
    <div className="rectangulo centrado busqueda">
      <form id="busqueda" action="buscar">
        <div className="input-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
          <input className="w100" type="text" name="user" placeholder="Buscar" /><br />
        </div>
      </form>
    </div>
    <div className="rectangulo round relative fondouno">
      {categoria.map(cat => (  
        <div key={cat.id} className='flex wrap izquierda rgap20 vtop'>
          <div className='w50'>
            <img className="w100" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/categorias/${cat.foto}`} alt={cat.name} />
          </div>
          <div className='w50 pl20'>
            <a href="receta"><h2 className='blanconegrita'>{cat.name}</h2></a>
          </div>
        </div>
      ))}  
    </div>
    {recetas?.map(receta => (  
    <div key={receta.id} className="rectangulo round relative fondoblanco">
        <div className='flex wrap izquierda rgap20 vtop'>
            <div className='w40'>
              <img className="w100" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`} alt={receta.name} />
            </div>
            <div className='w60 pl20'>
              <a href={`../receta/${receta.id}`}><h2 className='textooscuro'>{receta.name}</h2></a>
              <a className="w70" href={`buddymaker/${receta.author}`}><span className="textooscuro negrita">por {receta.username}</span></a>
              <br /><br />
              <span>{receta.description}</span>            
            </div>
        </div>
    </div>
    ))}
    
    <br /><br />
    <FooterMain />
  </div>);
};

export default Categoria;