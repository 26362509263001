import React from 'react';
import FooterMain from './FooterMain';

const About = () => {
  return (
    <div  className="contenedor">
      <div className="titulin">
        <a href="dashboard">
            <span className="simbologran">&lt;</span>
        </a>
        <h2 className="cuerpotexto">Sobre nosotros</h2>      
    </div>
      <div className="flex equiespaciado">
        <div className="center">Esta es la página sobre nosotros.</div>
        <br /><br />
        <FooterMain />
      </div>
    </div>
  );
};

export default About;