import client from "../client";

export default {
  list: (params) => client.get("recipes", {searchParams: params}).json(),
  get: (id) => client.get(`recipes/${id}`).json(),
  create: (body) => client.post('recipes', {body: body}).json(),
  update: (id, body) => client.post(`recipes/${id}`, {body: body}).json(),
  delete: (id) => client.delete(`recipes/${id}`).json(),

  image: (id) => client.get(`recipes/${id}/image?r=${Math.random()}`),
  imageUpdate: (id, body) => client.post(`recipes/${id}/image`, {body: body}),
};