import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

const UserFaqs = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpenIndex = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    { question: '¿Cómo puedo cambiar de tarjeta?', answer: 'Para cambiar de tarjeta, ve a la sección de métodos de pago en tu cuenta.' },
    { question: '¿Cómo puedo cambiar de tarjeta?', answer: 'Para cambiar de tarjeta, ve a la sección de métodos de pago en tu cuenta.' }
  ];

  return (
    <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
      <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={"/sidebarMenu"}>Preguntas frecuentes</Toolbar>
        <form className='h-full w-full mt-10'>
          <div className='flex flex-col items-center gap-3'>
            <h2 className='w-full text-left text-white text-base font-medium'>Pagos</h2>
            {faqs.map((faq, index) => (
              <div key={index} className='w-full'>
                <div
                  className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl cursor-pointer'
                  onClick={() => toggleOpenIndex(index)}
                >
                  <div className='grow px-2 text-start'>
                    <span className='text-buddy-text-1 font-semibold grow-0'>{faq.question}</span>
                  </div>
                  <ChevronRightIcon
                    className={`size-6 text-buddy-text-3 grow-0 transition-transform ${openIndex === index ? 'rotate-90' : ''}`}
                  />
                </div>
                {openIndex === index && (
                  <div className='bg-buddy-bg-3 mx-3 my-2 p-3 rounded-2xl'>
                    <p className='text-buddy-text-2'>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};
  
export default UserFaqs;