export function loginUserx(id, username, isLoggedIn, buddymaker) {
    localStorage.setItem('userId', id);
    localStorage.setItem('username', username);
    localStorage.setItem('isLoggedIn', isLoggedIn);
    localStorage.setItem('isBuddyMaker', buddymaker);
  }
  
export function logoutUserx() {
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('isBuddyMaker');
}
  
export function isAuthenticated() {
    return localStorage.getItem('isLoggedIn');
  }

export function isBuddyMaker() {
    return localStorage.getItem('isBuddyMaker');
}
  
export function getUserInfo() {
    return {
      id: localStorage.getItem('userId'),
      username: localStorage.getItem('username')
    };
  }