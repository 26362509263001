import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import usuario from "../img/usuariogran.png";
import FooterMain from './FooterMain';

const Buddymakers = () => {

  const [buddymakers, setBuddymakers] = useState([]);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [buddies, setBuddies] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetch('https://buddymarket.io/dummy/apibm/users/readbuddies.php')
      .then(response => response.json())
      .then(data => {
        setBuddymakers(data.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);


  const fetchBuddies = async () => {
    if(!!searchQuery){
      try {
        const response = await fetch(`https://buddymarket.io/dummy/apibm/recipes/searchbuddies.php?query=${searchQuery}`);
        const data = await response.json();
        setBuddies(data.data);
        console.log(data.data);
      } catch (error) {
        console.error('Error fetching buddies:', error);
      }
    }
  };

  const handleSearchChange = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 2) {
      await fetchBuddies();
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleBuddyClick = (budId) => {
    navigate(`../buddymaker/${budId}`)
  };

  return (
  <div className="contenedor">
     <div className="titulin">
        <Link to={-1}>
            <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">BuddyMakers</h2>      
    </div>
    <div className="rectangulo centrado busqueda">
      <form id="busqueda" action="buscar">
        <div className="input-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
          <input 
              className="w100" 
              type="text" 
              name="user" 
              autoComplete="off"
              placeholder="Buscar BuddyMakers"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <br />
            {isDropdownOpen && (
            <div className="dropdown">
              <ul>
                {buddies?.map((bud) => (
                  <li key={bud.id} onClick={() => handleBuddyClick(bud.id)}>
                    <img className="w20" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${bud.foto}`} alt={bud.username} /> <span className='textoblanco'>{bud.username}</span>
                  </li>
                ))}
              </ul>
            </div>
            )}
        </div>
      </form>
    </div>
    {buddymakers.map(buddy => (
    <div key={buddy.id} className="rectangulo round relative fondodos">
        <div className='flex wrap equiespaciado rgap20 vtop'>
          <a className="w100 vtop" href={`buddymaker/${buddy.id}`}>
            <img className="w20" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${buddy.foto}`} alt={buddy.username} />
             &nbsp;&nbsp;&nbsp;&nbsp;<span className="blanconegrita">{buddy.username}</span><br />
             
          </a>
        </div>
    </div>
    ))}
    
    
    <br /><br />
    <FooterMain />
  </div>);
};

export default Buddymakers;