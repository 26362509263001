import React, { useEffect, useState } from 'react';

function RecetaInstr({idreceta}){

  const [instrucciones, setInstrucciones] = useState([]);
  
  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readinstructions.php?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setInstrucciones(data.data);
      })
      .catch(error => console.error('Error fetching instructions:', error));
  }, []);

  return (
    <div>
      {instrucciones?.map((instruccion) => (
        <div key={instruccion.id} className="flex w100">
          {instruccion.tipo == 1 ? (
            <h3 className='izquierda block w100 mb20 mt10'>{instruccion.cadena}</h3>
          ) : (
            <div className='flex vcentrado gap10 rgap20 mb10'>
              {instruccion.cadena}
            </div>
          )}
        </div>
      ))}
    </div>
  );

};
export default RecetaInstr;