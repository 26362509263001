import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import AppRouter from './AppRouter';
import './index.css';
import { ModalProvider } from './contexts/modal';
import { App as CapacitorApp} from '@capacitor/app';

const root = ReactDOM.createRoot(document.getElementById('root'));
/*CapacitorApp.addListener('appUrlOpen', data => {
  alert('App opened with URL');
});

CapacitorApp.addListener('backButton', data => {
  alert('Backbutton pressed');
});*/

root.render(
  <React.StrictMode>
    <App>
      <ModalProvider>
        <Router>
          <AppRouter />
        </Router>
      </ModalProvider>
    </App>
  </React.StrictMode>
);
