import React from 'react';
import { getUserInfo } from '../auth';
import { useState, useEffect  } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from "react-router-dom";
import FooterMain from './FooterMain';

const Receta = () => {

  const [receta, setReceta] = useState([]);
  const [result, setResult] = useState([]);
  const [buddymaker, setBuddymaker] = useState([]);
  const { id } = useParams();
  const userinfo = getUserInfo();
  const navigate = useNavigate();
  const { register, formState , handleSubmit, watch, setValue, onChange } = useForm();
  const { idreceta } = useParams();
  const paginaperfil = "/perfil/"+userinfo.id;
  
  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/readbuddyrec.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setBuddymaker(data.data);
      })
      .catch(error => console.error('Error fetching buddymaker:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/read.php/?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setReceta(data.data);
      })
      .catch(error => console.error('Error fetching recipe:', error));
  }, []);

  const onSubmit = (data) => {

    /*console.log(data['name']);*/

    const formData = new FormData();
    formData.append("id", data['id']);

    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      /*headers: { 'Content-Type': 'application/json' },*/
      /*body: JSON.stringify(formData)*/
      body: formData
    };

    fetch(`https://buddymarket.io/dummy/apibm/recipes/delete.php/?id=${id}`,requestOptions)
      .then(response => response.json())
      .then(data => {
        setResult(data.data); 
        navigate(paginaperfil);
      })
      .catch(error => console.error('Error creating recipe:', error));

  }

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={paginaperfil}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Borrar receta</h2>      
      </div>
      
      <div className="rectangulo round relative fondooscuro">
        
        <div className='flex wrap centrado'>
            <h1 className='naranja w100 centrado'>Va a borrar la receta</h1> 
            <p className='w100 centrado textoblanco'>(Esta acción es irreversible)</p>
        </div>  
          <br />    
        {receta.map(rec => (  
        <div>
          <h2 className="mb20">{rec.name}</h2>
          <img className="w100 round mb20" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${rec.photo}`} alt={rec.name} /><br />
          <div className='w100 mb20'>
            {rec.description}
          </div>
        </div>
        ))}  
      </div>  
      <form className="izquierda" onSubmit={ handleSubmit(onSubmit)}>
      {receta.map(rec => (
        <div >
      <input type="hidden" name="author" value={userinfo.id} 
        {...register('author', {value:rec.author})} />
         <input type="hidden" name="id" value={idreceta} 
        {...register('id', {value:rec.id})} />
        </div>
      ))}
      <div className="w100">
        <button className="w100 submit fondonaranja" type="submit">Borrar receta</button>
      </div>      
      </form>
      <br /><br /><br />
      <FooterMain />
    </div>
    
  );
};

export default Receta;