import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import PhysicalExerciseFrequency from '../api/services/PhysicalExerciseFrequency';

const ProfileForm18 = () => {
  const navigate = useNavigate();

  const [physicalExerciseFrequencyId, setPhysicalExerciseFrequencyId] = useState('');
  const [physicalExerciseFrequencyOptions, setPhysicalExerciseFrequencyOptions] = useState([]);

  const handleClick = (e) => {
    if (physicalExerciseFrequencyId !== '') {
      const data = JSON.parse(localStorage.getItem('register_data'));
      data.physical_exercise_frequency_id = physicalExerciseFrequencyId;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm19');
    } else {
      alert('Responda a las preguntas no opcionales para continuar.');
    }
  }

  const handleOnChangePhysicalExerciseFrequencyId = (value) => {
    setPhysicalExerciseFrequencyId(value);
  }


  useEffect(() => {
    window.scrollTo(0, 0)

    loadPhysicalExerciseFrequencyOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setPhysicalExerciseFrequencyId(data.physical_exercise_frequency_id ?? '');
    }
  }, []);

  async function loadPhysicalExerciseFrequencyOptions() {
    try {
      const response = await PhysicalExerciseFrequency.list();
      var options = [];
      response.data.map(frequency => {
        options.push({
          value: frequency.id,
          label: frequency.name
        });
      });
      setPhysicalExerciseFrequencyOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm17"} replace={true}>
              <div className='text-sm'>
                <span >Paso 18</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Con qué frecuencia te ejercitas?</h2>
            <SelectList onChange={handleOnChangePhysicalExerciseFrequencyId} selected={physicalExerciseFrequencyId} options={physicalExerciseFrequencyOptions} />
            <div className='my-2'></div>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm18;