import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import FoodCategory from "../api/services/FoodCategory.js";
import Recipe from "../api/services/Recipe.js";
import Toolbar from "./ui/Toolbar.js";
import Input from "./forms/Input.js";
import Textarea from "./forms/Textarea.js";
import Measure from "../api/services/Measure.js";
import Ingredient from "../api/services/Ingredient.js";
import SelectRounded from "./forms/SelectRounded.js";
import SmallToggle from "./forms/SmallToggle.js";
import FooterToolbar from "./ui/FooterToolbar.js";
import PrimaryButton from "./ui/PrimaryButton.js";
import SecondaryButton from "./ui/SecondaryButton.js";
import SecondaryOutLineButton from "./ui/SecondaryOutlineButton.js";
import { CameraIcon, CheckIcon, PlusIcon, PencilIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const RecipeCreate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const isEditing = Boolean(id);

  const [foodCategoryOptions, setFoodCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [status, setStatus] = useState(0);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [rations, setRations] = useState(1);
  const [cookTime, setCookTime] = useState('');
  const [preparationTime, setPreparationTime] = useState('');

  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(null);

  const [allIngredients, setAllIngredients] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [ingredientSearchEnabled, setIngredientSearchEnabled] = useState(true);
  const [ingredient, setIngredient] = useState(null);
  const [ingredientHeaders, setIngredientHeaders] = useState([]);

  const [stepHeaders, setStepHeaders] = useState([]);

  const [recipeStep, setRecipeStep] = useState('');
  const [editIngredientDetails, setEditIngredientDetails] = useState(null);
  const [editStepDetails, setEditStepDetails] = useState(null);

  const [measureId, setMeasureId] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);

  const [newIngredientHeader, setNewIngredientHeader] = useState('');
  const [newStepHeader, setNewStepHeader] = useState('');

  const [editIngredientHeaderIndex, setEditIngredientHeaderIndex] = useState(null);
  const [editStepHeaderIndex, setEditStepHeaderIndex] = useState(null);
  
  // New state variables
  const [isEditingIngredient, setIsEditingIngredient] = useState(false);
  const [isEditingStep, setIsEditingStep] = useState(false);

  const statusOptions = [
    { value: 1, label: "Publicada" },
    { value: 0, label: "Borrador" }
  ];

  useEffect(() => {
    if(isEditing,id){
      fetchInitialData(isEditing,id);
    }
  }, [isEditing, id]);

  const fetchInitialData = async (isEditing,id) => {
    try {
      await getMeasures();
      await getAllIngredients();
      await getFoodCategories(isEditing,id);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
  
  const getRecipe = async (recipeId,foodCategoryOptions) => {
    try {
      const response = await Recipe.get(recipeId);
      const recipe = response.data;

      const categories = recipe.recipeFoodCategories.map(cat => ({
        id: cat.food_category_id,
        name: foodCategoryOptions.find(option => option.id === cat.food_category_id)?.name || ''
      }));
      setSelectedCategories(categories);

      setName(recipe.name);
      setDescription(recipe.description);
      setPreparationTime(recipe.preparation_time.toString());
      setCookTime(recipe.cook_time.toString());
      setRations(recipe.rations.toString());
      setStatus(recipe.active ? 1 : 0);
  
      const groupedIngredients = groupBy(recipe.recipeIngredients, 'header_name');
      const ingredientHeaders = Object.entries(groupedIngredients).map(([header, ingredients]) => ({
        header,
        ingredients: ingredients.map(item => ({
          ingredient_id: item.ingredient.id,
          label: item.ingredient.name,
          quantity: item.quantity,
          measure_id: item.measure.id,
          measure_label: item.measure.name,
          image_url: item.ingredient.image_url
        }))
      }));
  
      const groupedSteps = groupBy(recipe.recipeSteps, 'header_name');
      const stepHeaders = Object.entries(groupedSteps).map(([header, steps]) => ({
        header,
        steps: steps.map(item => ({
          description: item.description
        }))
      }));
  
      setIngredientHeaders(ingredientHeaders);
      setStepHeaders(stepHeaders);
    } catch (error) {
      console.error('Error fetching recipe:', error);
    }
  };
  
  const groupBy = (array, key) => {
    if (!array || array.length === 0) return {};
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const getImage = async (recipeId) => {
    try {
      const response = await Recipe.image(recipeId);
      const blob = await response.blob();
      setImageData(URL.createObjectURL(blob));
    } catch (error) {
      console.error('Error fetching recipe image:', error);
    }
  };

  const handleSave = async () => {
    try {
      const body = new FormData();
      body.append('name', name);
      if (imageFile) {
        body.append('image_file', imageFile);
      }
      body.append('description', description);
      body.append('preparation_time', parseInt(preparationTime));
      body.append('cook_time', parseInt(cookTime));
      body.append('rations', parseInt(rations));
      body.append('food_category_ids', JSON.stringify(selectedCategories.map(c => ({ id: c.id }))));
      body.append('ingredients', JSON.stringify(ingredientHeaders));
      body.append('steps', JSON.stringify(stepHeaders));
      body.append('active', status);

      if (isEditing) {
        await Recipe.update(id, body);
        alert("Receta actualizada correctamente");
      } else {
        await Recipe.create(body);
        alert("Receta creada correctamente");
      }

      navigate('/', { replace: true });
    } catch (error) {
      alert(error.response?.payload?.message ?? 'Error inesperado. Inténtalo de nuevo.');
      console.error('Error saving recipe:', error);
    }
  };

  const getFoodCategories = async (isEditing,id) => {
    try {
      const response = await FoodCategory.list();
      setFoodCategoryOptions(response.data);
      if (isEditing) {
        await getRecipe(id,response.data);
        await getImage(id);
      }
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      const options = response.data.map(type => ({
        value: type.id,
        label: type.name
      }));
      setMeasureOptions(options);
      setMeasureId(options[0]?.value ?? null);
    } catch (error) {
      console.error('Error fetching Measure:', error);
    }
  };

  const getAllIngredients = async () => {
    try {
      const response = await Ingredient.list();
      setAllIngredients(response.data);
    } catch (error) {
      console.error('Error fetching Ingredients:', error);
    }
  };

  const getIngredients = (search) => {
    if (!search || search.length < 3) {
      setIngredients([]);
      return;
    }

    const filteredIngredients = allIngredients
      .filter(ingredient => ingredient.name.toLowerCase().includes(search.toLowerCase()))
      .slice(0, 10);
    setIngredients(filteredIngredients);
  };

  const addIngredient = (headerIndex) => {
    if (ingredientHeaders.length === 0) {
      alert("Primero debes crear un encabezado para los ingredientes.");
      return;
    }

    if (!document.querySelector('[name="quantity"]').value) {
      alert("Es necesario indicar la cantidad");
    } else {
      const measure = measureOptions.find(option => option.value === measureId);
      
      const updatedHeaders = ingredientHeaders.map((item, index) => {
        if (index === headerIndex) {
          const newIngredient = {
            ingredient_id: ingredient.id,
            quantity: parseInt(document.querySelector('[name="quantity"]').value),
            measure_id: measureId,
            label: ingredient.name,
            image_url: ingredient?.image_url,
            measure_label: measure.label
          };
          return {
            ...item,
            ingredients: editIngredientDetails
              ? item.ingredients.map((ing, i) => i === editIngredientDetails.ingredientIndex ? newIngredient : ing)
              : [...item.ingredients, newIngredient]
          };
        }
        return item;
      });
      setIngredientHeaders(updatedHeaders);
      resetIngredientEditing();
    }
  };

  const addStep = (headerIndex) => {
    if (stepHeaders.length === 0) {
      alert("Primero debes crear un encabezado para los pasos.");
      return;
    }

    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        const newStep = { description: recipeStep };
        return {
          ...item,
          steps: editStepDetails
            ? item.steps.map((step, i) => i === editStepDetails.stepIndex ? newStep : step)
            : [...item.steps, newStep]
        };
      }
      return item;
    });
    setStepHeaders(updatedHeaders);
    resetStepEditing();
  };

  const handleEditIngredient = (headerIndex, ingredientIndex) => {
    const ingredientToEdit = ingredientHeaders[headerIndex].ingredients[ingredientIndex];
    setEditIngredientDetails({ headerIndex, ingredientIndex });
    setIngredientSearch(ingredientToEdit.label);
    setIngredientSearchEnabled(false);
    setIngredient({
      id: ingredientToEdit.ingredient_id,
      name: ingredientToEdit.label,
      image_url: ingredientToEdit.image_url
    });
    setMeasureId(ingredientToEdit.measure_id);
    setIsEditingIngredient(true);
    document.querySelector('[name="quantity"]').value = ingredientToEdit.quantity;
  };

  const handleDeleteIngredient = (headerIndex, ingredientIndex) => {
    const updatedHeaders = ingredientHeaders.map((item, index) => {
      if (index === headerIndex) {
        return {
          ...item,
          ingredients: item.ingredients.filter((_, i) => i !== ingredientIndex)
        };
      }
      return item;
    });
    setIngredientHeaders(updatedHeaders);
  };

  const handleEditStep = (headerIndex, stepIndex) => {
    const stepToEdit = stepHeaders[headerIndex].steps[stepIndex];
    setEditStepDetails({ headerIndex, stepIndex });
    setRecipeStep(stepToEdit.description);
    setIsEditingStep(true);
  };

  const handleDeleteStep = (headerIndex, stepIndex) => {
    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        return {
          ...item,
          steps: item.steps.filter((_, i) => i !== stepIndex)
        };
      }
      return item;
    });
    setStepHeaders(updatedHeaders);
  };

  const handleCategorySelect = (category) => {
    if (!selectedCategories.some(selected => selected.id === category.id)) {
      setSelectedCategories([...selectedCategories, category]);
      setCategorySearch('');
    }
  };

  const handleRemoveCategory = (category) => {
    setSelectedCategories(selectedCategories.filter(selected => selected.id !== category.id));
  };

  const handleOnChangeIngredientSearch = (e) => {
    const search = e.target.value;
    setIngredientSearch(search);
    getIngredients(search);
  };

  const handleOnFocusIngredientSearch = (e) => {
    if (ingredientSearchEnabled) {
      const ingredientSearch = e.target.value;
      if (ingredientSearch.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(e.target.value);
      }
    }
  };

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  };

  const handleResetIngredient = () => {
    setIngredientSearch('');
    document.querySelector('[name="ingredient_search"]').value = '';
    setIngredients([]);
    setIngredient(null);
    setIngredientSearchEnabled(true);
    resetIngredientEditing();
  };

  const resetIngredientEditing = () => {
    setEditIngredientDetails(null);
    setIsEditingIngredient(false);
  };

  const resetStepEditing = () => {
    setEditStepDetails(null);
    setRecipeStep('');
    setIsEditingStep(false);
  };

  const handleOnIngredientSelected = (selectedIngredient) => {
    document.querySelector('[name="ingredient_search"]').value = selectedIngredient.name;
    setIngredientSearch(selectedIngredient.name);
    setIngredients([]);
    setIngredientSearchEnabled(false);
    setIngredient(selectedIngredient);
  };

  const onChangeImageFile = e => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => setImageData(reader.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onResetImageFile = () => {
    setImageFile(null);
    setImageData(null);
  };

  const saveEditedIngredientHeader = (headerIndex) => {
    if (!newIngredientHeader.trim()) {
      alert("El encabezado no puede estar vacío.");
      return;
    }
    const updatedHeaders = ingredientHeaders.map((item, index) => {
      if (index === headerIndex) {
        return { ...item, header: newIngredientHeader };
      }
      return item;
    });

    setIngredientHeaders(updatedHeaders);
    setEditIngredientHeaderIndex(null);
    setNewIngredientHeader('');
  };

  const saveEditedStepHeader = (headerIndex) => {
    if (!newStepHeader.trim()) {
      alert("El encabezado no puede estar vacío.");
      return;
    }
    const updatedHeaders = stepHeaders.map((item, index) => {
      if (index === headerIndex) {
        return { ...item, header: newStepHeader };
      }
      return item;
    });

    setStepHeaders(updatedHeaders);
    setEditStepHeaderIndex(null);
    setNewStepHeader('');
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={"/"} replace={true}>
          <div className='text-md'>
            <span>{isEditing ? "Editar Receta" : "Crear Receta"}</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-4 space-y-3">
          <Input name="name" label={'Nombre de la receta'} value={name} onChange={(e) => setName(e.target.value)} />

          <input
            type="file"
            name="image_file"
            onChange={onChangeImageFile}
            className="hidden"
          />

          { 
            imageData ? 
            <div className="flex justify-center py-4">
              <div className="relative flex justify-center items-center w-52 h-52">
                <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4 overflow-hidden">
                  <img onClick={() => document.querySelector('[name="image_file"]').click()} className="h-full w-full object-cover object-center" src={imageData} />
                </div>
                <button type='button' onClick={onResetImageFile} className='absolute -right-2 -top-2 flex items-center justify-center w-8'>
                  <div className='w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center'>
                      <FontAwesomeIcon className='text-white' icon={faTimes} />
                  </div>
                </button>
              </div>
            </div>
            :
            <div className="flex justify-center py-4">
              <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4">
                <button onClick={() => document.querySelector('[name="image_file"]').click()} className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2">
                  <CameraIcon className="size-6" />
                  <span className="text-sm">Añadir foto de portada</span>
                </button>
              </div>
            </div>
          }

          <Textarea value={description} onChange={(e) => setDescription(e.target.value)} name="description" label="Descripción del plato" />

          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Ingredientes</p> 
            <button
              onClick={() => setNewIngredientHeader('')}
              style={{ backgroundColor: "#394a53" }}
              className="text-primary rounded-lg py-1 px-3 mb-3 inline-flex items-center space-x-2"
            >
              <PlusIcon className="size-4" /> <span>Añadir encabezado</span>
            </button>

            {ingredientHeaders.map((group, headerIndex) => (
              <div key={headerIndex}>
                {editIngredientHeaderIndex === headerIndex ? (
                  <input
                    type="text"
                    value={newIngredientHeader}
                    onChange={(e) => setNewIngredientHeader(e.target.value)}
                    onBlur={() => saveEditedIngredientHeader(headerIndex)}
                    className="bg-transparent text-white mt-2 mb-1"
                    autoFocus
                  />
                ) : (
                  <h3
                    className="text-orange-500 font-semibold my-2 cursor-pointer"
                    onClick={() => {
                      setEditIngredientHeaderIndex(headerIndex);
                      setNewIngredientHeader(group.header);
                    }}
                  >
                    {group.header}
                  </h3>
                )}
                {group.ingredients.map((ingredient, i) => (
                  <div key={i} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                    {ingredient.image_url && <img className="w-5 h-5 rounded-full" src={ingredient.image_url} alt={ingredient.label}></img>}
                    <div className="grow">{ingredient.label} {ingredient.quantity} {ingredient.measure_label}</div>
                    <button onClick={() => handleEditIngredient(headerIndex, i)}>
                      <PencilIcon className="size-5 text-buddy-text-primary" />
                    </button>
                    <button onClick={() => handleDeleteIngredient(headerIndex, i)}>
                      <TrashIcon className="size-5 text-buddy-text-danger" />
                    </button>
                  </div>
                ))}
              </div>
            ))}

            <div className={'flex justify-content-center space-x-4 mt-14'}>
              <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                {ingredientSearch?.length > 0 && (
                  <div className="absolute right-0 bottom-0 top-0">
                    <button type='button' onClick={handleResetIngredient} className='flex items-center justify-center w-8 me-2 h-full'>
                      <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                        <FontAwesomeIcon className='text-white' icon={faTimes} />
                      </div>
                    </button>
                  </div>
                )}
                <input readOnly={!ingredientSearchEnabled} onFocus={handleOnFocusIngredientSearch} onChange={handleOnChangeIngredientSearch} autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                    name="ingredient_search"
                    type="text"
                    placeholder="Buscar ingredientes"
                />
                <div className="absolute left-2 top-4">
                  <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                </div>
              </div>
              <div className="flex flex-col w-1/4 text-buddy-text-1 -mt-6">
                <span className="text-xs text-center">Unidad</span>
                <SelectRounded name="measure_id" onChange={handleOnChangeMeasureId} options={measureOptions} />
              </div>
              <div className="flex flex-col text-buddy-text-1 w-1/4 -mt-6">
                <span className="text-xs mb-2 text-center">Cantidad</span>
                <div className='w-full'>
                  <div className="w-full h-11 bg-secondary-bg rounded-lg relative shadow-inner ring-white ring-2">
                    <input aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-center text-sm text-white placeholder:font-light rounded-lg"
                      name="quantity"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="ingredientsList" className="w-full z-10 absolute flex flex-col bg-opacity-90 bg-buddy-bg-5 rounded-lg overflow-hidden">
              {
                ingredients.map((ingredient, i) => (
                  <button key={i} onClick={() => handleOnIngredientSelected(ingredient)} className="w-full text-start text-buddy-text-2 py-2 px-3">{ingredient.name}</button>
                ))
              }
            </div> 
            {
              isEditingIngredient &&
              <div className="flex justify-end space-x-2 mt-3">
                <SecondaryOutLineButton onClick={handleResetIngredient}>
                  <XMarkIcon className="size-4 me-1" /> <span> Cancelar</span>
                </SecondaryOutLineButton>
                <SecondaryButton onClick={() => addIngredient(editIngredientDetails ? editIngredientDetails.headerIndex   : ingredientHeaders.length - 1)}>
                  <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary"> Guardar</span>
                </SecondaryButton>
              </div>
            }
          </div>

          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Instrucciones</p> 

            <button
              onClick={() => setNewStepHeader('')}
              style={{ backgroundColor: "#394a53" }}
              className="text-primary rounded-lg py-1 px-3 mb-3 inline-flex items-center space-x-2"
            >
              <PlusIcon className="size-4" /> <span>Añadir encabezado</span>
            </button>

            {stepHeaders.map((group, headerIndex) => (
              <div key={headerIndex}>
                {editStepHeaderIndex === headerIndex ? (
                  <input
                    type="text"
                    value={newStepHeader}
                    onChange={(e) => setNewStepHeader(e.target.value)}
                    onBlur={() => saveEditedStepHeader(headerIndex)}
                    className="bg-transparent text-white mt-2 mb-1"
                    autoFocus
                  />
                ) : (
                  <h3
                    className="text-orange-500 font-semibold my-2 cursor-pointer"
                    onClick={() => {
                      setEditStepHeaderIndex(headerIndex);
                      setNewStepHeader(group.header);
                    }}
                  >
                    {group.header}
                  </h3>
                )}
                {group.steps.map((step, i) => (
                  <div key={i} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                    <div className="grow">{step.description}</div>
                    <button onClick={() => handleEditStep(headerIndex, i)}>
                      <PencilIcon className="size-5 text-buddy-text-primary" />
                    </button>
                    <button onClick={() => handleDeleteStep(headerIndex, i)}>
                      <TrashIcon className="size-5 text-buddy-text-danger" />
                    </button>
                  </div>
                ))}
              </div>
            ))}

            <div className='flex space-x-4 mt-10'>
              <div className="w-full shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                <input onChange={(e) => setRecipeStep(e.target.value)} value={recipeStep} aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                      name="recipe_step"
                      type="text"
                      placeholder="Añadir paso"
                />
              </div>
            </div>

            { isEditingStep &&
              <div className="flex justify-end mt-2">
                <SecondaryButton onClick={() => addStep(editStepDetails ? editStepDetails.headerIndex : stepHeaders.length - 1)}>
                  <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary">Guardar</span>
                </SecondaryButton>
              </div>
            }
          </div>

          <Input name="rations" label={"Establecer raciones"} type="number" value={rations} onChange={(e) => setRations(e.target.value)} />
          <div className="text-xs text-primary mt-1 mb-2">Esto se utiliza para calcular las raciones en base a las cantidades y calcular el valor nutricional de la receta.</div>

          <Input name="cook_time" label={"Tiempo de cocción (minutos)"} type="number" value={cookTime} onChange={(e) => setCookTime(e.target.value)} />

          <Input name="preparation_time" label={"Tiempo de preparación (minutos)"} type="number" value={preparationTime} onChange={(e) => setPreparationTime(e.target.value)} />

          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2">Categorías</p> 

            <input 
              type="text" 
              value={categorySearch} 
              onChange={(e) => setCategorySearch(e.target.value)} 
              className="w-full p-2 bg-white rounded mb-2" 
              placeholder="Buscar categoría"
            />
            
            <div className="flex flex-wrap gap-2">
              {selectedCategories.map(category => (
                <div key={category.id} className="flex items-center px-3 py-1 bg-white rounded cursor-pointer">
                  <span>{category.name}</span>
                  <button 
                    onClick={() => handleRemoveCategory(category)}
                    style={{ backgroundColor: "rgb(255, 140, 0)" }}
                    className="ml-2 text-white px-1 rounded"
                  >
                    ×
                  </button>
                </div>
              ))}
              {foodCategoryOptions
                .filter(c => c.name.toLowerCase().includes(categorySearch.toLowerCase()))
                .filter(c => !selectedCategories.some(selected => selected.id === c.id))
                .map(category => (
                  <div 
                    key={category.id}
                    onClick={() => handleCategorySelect(category)}
                    style={{ backgroundColor: "#424E55", color: "white" }}
                    className="flex items-center px-3 py-1 rounded cursor-pointer"
                  >
                    {category.name}
                  </div>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <SmallToggle selected={status} onChange={(value) => setStatus(value)} options={statusOptions} label="Estado de la Receta" />
          </div>

          <div className="my-4"></div>

          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleSave}>Guardar</PrimaryButton>
          </FooterToolbar>
        </div>
      </div>
    </div>
  );
};

export default RecipeCreate;