import React, { useState, useEffect, useRef } from 'react';
import Toolbar from './ui/Toolbar';
import Chat from "../api/services/Chat.js";

const BuddyChat = () => {
  const [messages, setMessages] = useState([
    { text: 'Pregúntame lo que quieras sobre nutrición, alimentación, etc', sender: 'bot' },
  ]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado de carga
  const messagesEndRef = useRef(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim() || isLoading) return; // Evita enviar si está cargando

    const newMessages = [...messages, { text: userInput, sender: 'user' }];
    setMessages(newMessages);
    setUserInput('');
    setIsLoading(true); // Iniciar el estado de carga

    try {
      const body = new FormData();
      body.append('messages', JSON.stringify(newMessages));
      const response = await Chat.chat(body);
      if (response && response.answer) {
        setMessages([...newMessages, { text: response.answer, sender: 'bot' }]);
      } else {
        throw new Error('Invalid data structure');
      }
    } catch (error) {
      console.error('Error fetching API:', error);
      setMessages([...newMessages, { text: "Lo siento, hubo un error procesando tu solicitud.", sender: 'bot' }]);
    } finally {
      setIsLoading(false); // Terminar el estado de carga, independientemente de éxito o error
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isLoading) {
      e.preventDefault(); // Evita que "Enter" envíe el formulario si está cargando
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center flex flex-col'>
      <div className='bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={'/sidebarMenu'}>
          <div className='text-md'>Buddy Chat</div>
        </Toolbar>
      </div>
      <div className="flex-1 overflow-y-auto" style={{ backgroundColor: "rgb(39 55 64)", padding: '24px' }}>
        <div className="flex flex-col space-y-4">
          {messages.map((msg, index) => (
            <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : ''}`}>
              <div className={`py-2 px-3 rounded-lg max-w-xs ${msg.sender === 'user' ? 'bg-buddy-bg-3 text-white' : 'bg-buddy-bg-2 text-primary'}`}>
                {msg.text}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div>
        {isLoading && <div className="text-white text-center mb-2">El asistente está pensando...</div>}
        <form onSubmit={handleSubmit} className="bg-buddy-bg-2 p-4 flex items-center rounded-lg">
          <input 
            style={{ border: "none" }} 
            type="text" 
            placeholder="Escribe tu mensaje..."
            value={userInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Manejar la tecla enter
            className="bg-buddy-bg-3 flex-1 border text-white rounded-full px-4 py-2 focus:outline-none"
          />
          <button 
            style={{ backgroundColor: "rgb(255 140 0)" }} 
            className="text-white rounded-full p-2 ml-2 hover:bg-blue-600 focus:outline-none"
            type="submit"
            disabled={isLoading} // Desactivar el botón cuando esté cargando
          >
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 12h-6.08m-.17.797l-1.004 3.001c-.55 1.644-.824 2.466-.626 2.972.172.44.54.773.994.9.524.146 1.314-.209 2.895-.92l10.134-4.561c1.543-.695 2.315-1.042 2.553-1.524.207-.42.207-.912 0-1.331-.238-.482-1.01-.829-2.553-1.524l-10.151-4.569c-1.576-.709-2.364-1.063-2.888-.917-.454.127-.822.46-.994.9-.198.506.074 1.326.62 2.966l1.026 3.091c.094.282.141.423.158.567.017.128.017.257 0 .385-.02.144-.067.285-.161.567z"
                stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default BuddyChat;