import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Toggle from './forms/Toggle';
import MeasureInput from './forms/MeasureInput';
import UserHeightType from '../api/services/UserHeightType';

const ProfileForm16 = () => {
  const navigate = useNavigate();

  const [heightTypeId, setHeightTypeId] = useState('');
  const [heightTypeOptions, setHeightTypeOptions] = useState([]);
  const [height, setHeight] = useState('');
 
  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.user_height_type_id = heightTypeId;
    data.height = height;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm17');
  }

  const handleOnChangeHeightTypeId = (value) => {
    setHeightTypeId(value);
  }

  const handleOnChangeHeight = (value) => {
    setHeight(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    loadHeightTypeOptions();

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setHeightTypeId(data.user_height_type_id ?? 1);
      setHeight(data.height ?? '');
    }
  }, []);

  async function loadHeightTypeOptions() {
    try {
      const response = await UserHeightType.list();
      var options = [];
      response.data.map(type => {
        options.push({
          value: type.id,
          label: type.name
        });
      });
      setHeightTypeOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={"/profileForm15"} replace={true}>
              <div className='text-sm'>
                <span >Paso 16</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Estatura <span className='text-primary'>(opcional)</span></h2>
            <div className='w-2/3 mt-6'>
              <Toggle onChange={handleOnChangeHeightTypeId} selected={heightTypeId} options={heightTypeOptions} />
            </div>
            <div className='my-2'></div>
            <MeasureInput id="height" name="height" value={height} onChange={handleOnChangeHeight}  type="number"></MeasureInput>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm16;