import React from 'react'

const Helpbox = ({ text }) => {
    return (
        <>
            <div className="w-full text-primary text-sm">
                {text}
            </div>
        </>
    )
}
export default Helpbox 