import React, { useEffect, useState } from 'react';
import Toolbar from './ui/Toolbar';
import User from '../api/services/User';
import Recipe from "../api/services/Recipe.js";
import { useParams } from 'react-router-dom';
import Textarea from "./forms/Textarea.js";
import instagramIcon from '../icons/instagram.png';
import linkedinIcon from '../icons/linkedin.png';
import youtubeIcon from '../icons/youtube.png';
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import BuddyRecipeCard from './ui/BuddyRecipeCard.js';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Buddymakers = () => {
  const { id } = useParams();
  const [buddyMaker, setBuddyMaker] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [showAllRecipes, setShowAllRecipes] = useState(false);

  useEffect(() => {
    getBuddyMaker();
    getRecipes();
  }, []);

  const getBuddyMaker = async () => {
    try {
      const response = await User.get(id);
      setBuddyMaker(response.data);
    } catch (error) {
      console.error('Error fetching buddymaker users:', error);
    }
  };

  const getRecipes = async () => {
    try {
      const response = await Recipe.list({ user_id: id });
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const ensureUrlProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const handleViewAllClick = () => {
    setShowAllRecipes(!showAllRecipes);
  };

  if (showAllRecipes) {
    return (
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col items-center gap-3 pb-10">
          <div className='w-full h-12  flex justify-start text-title items-center text-lg mb-2'>
            <div className='w-10 h-10 rounded-full absolute bg-white bg-opacity-20 flex flex-col justify-center items-center'>
                <button onClick={handleViewAllClick} className='text-title w-10 h-10'><FontAwesomeIcon icon={faChevronLeft} /></button>
            </div>
            <div className='grow text-center'>
                <div className='font-bold '>Todas las Recetas</div>
            </div>
          </div>
          <div className="w-full flex flex-col space-y-4 mt-4">
            {recipes.map((recipe) => (
              <BuddyRecipeCard recipe={recipe} key={recipe.id} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3 pb-10">
          <Toolbar to={"/buddyMakerList"}>{buddyMaker.name}</Toolbar>

          {buddyMaker.image_url &&
            <div className="flex justify-center py-4">
              <div className="relative flex justify-center items-center w-52 h-52">
                <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4 overflow-hidden">
                  <img className="h-full w-full object-cover object-center" src={buddyMaker.image_url} />
                </div>
              </div>
            </div>
          }

          <Textarea value={buddyMaker.description} name="description" label="Acerca de mí" />

          <h2 style={{ fontSize: '16px', fontWeight: '500' }} className='w-full text-left text-white mt-6'>Mis Redes Sociales</h2>
          <div className="w-full flex flex-col gap-2">
            {buddyMaker.instagram &&
              <a href={ensureUrlProtocol(buddyMaker.instagram)} target="_blank" rel="noopener noreferrer" className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl' style={{ alignItems: 'center' }}>
                <div className='flex justify-start gap-3'>
                  <img src={instagramIcon} style={{ width: '24px', height: '24px' }} />
                  <div className='px-2 text-start'>
                    <span className='text-buddy-text-1 mt-auto mb-auto font-semibold'>{buddyMaker.instagram ?? ''}</span>
                  </div>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0 mt-auto mb-auto' />
              </a>
            }
            {buddyMaker.linkedin &&
              <a href={ensureUrlProtocol(buddyMaker.linkedin)} target="_blank" rel="noopener noreferrer" className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl' style={{ alignItems: 'center' }}>
                <div className='flex justify-start gap-3'>
                  <img src={linkedinIcon} style={{ width: '24px', height: '24px' }} />
                  <div className='px-2 text-start'>
                    <span className='text-buddy-text-1 mt-auto mb-auto font-semibold'>{buddyMaker.linkedin ?? ''}</span>
                  </div>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0 mt-auto mb-auto' />
              </a>
            }
            {buddyMaker.youtube &&
              <a href={ensureUrlProtocol(buddyMaker.youtube)} target="_blank" rel="noopener noreferrer" className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl' style={{ alignItems: 'center' }}>
                <div className='flex justify-start gap-3'>
                  <img src={youtubeIcon} style={{ width: '24px', height: '24px' }} />
                  <div className='px-2 text-start'>
                    <span className='text-buddy-text-1 mt-auto mb-auto font-semibold'>{buddyMaker.youtube ?? ''}</span>
                  </div>
                </div>
                <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0 mt-auto mb-auto' />
              </a>
            }
          </div>

          <div className="w-full flex justify-between items-center mt-6">
            <h2 style={{ fontSize: '16px', fontWeight: '500' }} className='w-full text-left text-white'>Últimas recetas</h2>
            <span onClick={handleViewAllClick} style={{ minWidth: '120px', width: '120px' }} className="text-primary text-center text-sm font-medium bg-buddy-view-all rounded-lg py-1 px-3 cursor-pointer">
              Ver todas
            </span>
          </div>
          <div className="w-full flex flex-col space-y-4">
            {recipes.slice(0, 2).map((recipe) => (
              <BuddyRecipeCard recipe={recipe} key={recipe.id} />
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Buddymakers;